import React, { useEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { HeaderBoarder } from '../Components'
import { useForm } from 'react-hook-form';
import { CityDropDown, Input, SelectInput, Textarea } from '../FormsInputes.js';
import Swal from 'sweetalert2';
import { addSuggession, guestUpdate, isMobileNumberRegisterd, postApi } from '../service/Services.js';
import AdminButton from './AdminButtons.js';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
const typeOfSuggestion = [
    "कमिटी",
    "मेंबर",
    "असुविधा",
    "कार्यक्रम",
    "धर्मशाला बुकिंग",
    "डोनेशन",
    "लागा पेमेंट",
    "शादी रिश्ते",
    "मोबाइल ऐप",
    "मंदिर",
    "निमंत्रण"
];
const initialFormData = {
    profilePhoto: "",
    membershipCode: 'Not Actived',
    bloodGroup: 'A+',
    name: '',
    email: '',
    surname: '',
    mobileNo: '',
    whatsappNo: '',
    fatherName: '',
    motherName: '',
    gotra: '',
    showMobileNo: true,
    gender: "Male",
    birthDate: '',
    permanentAddress: '',
    currentAddress: '',
    city: '',
    country: '',
    State: '',
    pincode: '',
    currentAddressArea: '',
    occupation: '',
    educationQualification: '',
    memberFamilyDetails: [{
        memberName: '',
        age: '',
        dob: '',
        gender: 'Male',
        relation: '',
        maritalStatus: ''
    }]

};


export function GuestRegistrationForm({ aleadySetData }) {

    const user = useSelector(state => state?.auth?.user);
    // const authData = useSelector(state => state.auth);
    let isLogin = useSelector(state => state?.auth?.isLogin);
    let formData = user ? user : initialFormData;

    const location = useLocation()

    if (aleadySetData) {
        formData = aleadySetData
    }


    // console.log("location.pathname.startsWith('/admin/GuestRegistrationFrom')", location.pathname.startsWith('/admin/GuestRegistrationFrom'))
    if (location.pathname.startsWith('/admin/GuestRegistrationFrom')) {
        formData = initialFormData;
        isLogin = false
    }

    const { register, handleSubmit, setError, formState: { errors }, setValue, getValues, reset } = useForm({
        ...formData
    });

    console.log("formDataformData", formData)
    const [isLoading, setLoading] = useState(false)
    // const [isError, setError] = useState(false)
    const [isMoblieNumberAlreadyExist, setsIMoblieNumberAlreadyExist] = useState(false)
    const [mobileNumberCheckLoading, setMobileNumberCheckLoading] = useState(false)
    const [cityOptions, setCityOptions] = useState([]);
    const [currentCity, setCurrentCity] = useState(formData?.city || "");





    const submit = async (data) => {
        data.city = JSON.stringify(currentCity);
        data.userType = 7
        console.log(data)
        // return
        try {
            // const requiredFields = ['suggesstionsFor', 'suggesstions',];
            // const hasMissingFields = requiredFields.some(field => !data[field]);
            // if (hasMissingFields) {
            //     throw new Error("All Star fields are required");
            // }
            setLoading(true);
            console.log(data)
            // Call addNews API
            const isNewsAdded = await postApi('/guest/guest-registration', data);
            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const editGuest = async (data) => {



        data.city = JSON.stringify(currentCity);
        data.userType = 7
        console.log(data)
        // return
        try {
            // const requiredFields = ['suggesstionsFor', 'suggesstions',];
            // const hasMissingFields = requiredFields.some(field => !data[field]);
            // if (hasMissingFields) {
            //     throw new Error("All Star fields are required");
            // }
            setLoading(true);
            console.log(data)
            // Call addNews API
            const isGuestUpdate = await guestUpdate(data);
            // Check if news added successfully
            if (isGuestUpdate?.success) {
                Swal.fire({
                    title: isGuestUpdate.success,
                    text: isGuestUpdate.message,
                    icon: 'success',
                });
                reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    }



    const handleRegisterMobileNoChange = async (e) => {
        let value = e.target.value;
        setMobileNumberCheckLoading(false);
        setsIMoblieNumberAlreadyExist(false);

        // Remove non-numeric characters
        value = value.replace(/\D/g, '');

        if (value.length > 10) {
            value = value.slice(0, 10); // Limit the value to 10 digits
        }

        setValue("mobileNo", value, { shouldValidate: true });


        if (value.length === 10) {
            setsIMoblieNumberAlreadyExist(true);
            await mobileNumberCheckHandler(value);
        }
    };
    const mobileNumberCheckHandler = async (number) => {
        try {
            const responce = await isMobileNumberRegisterd({ register_MobileNumber: number })
            console.log("responce", responce);
            if (responce?.success) {
                setMobileNumberCheckLoading(true)
            }
        } catch (error) {
            console.log(error)
            setError('mobileNo', {
                type: 'manual',
                message: 'Mobile Number Already Exists',
            });
            // setMobileNumberCheckLoading(true)
        } finally {
            setsIMoblieNumberAlreadyExist(false)
        }

    }
    // addSuggession
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };


    return (
        <>
            {/* <HeaderBoarder heading={"सुझाव"} /> */}
            <div className='w-100  md:px-10 '>
                <form onSubmit={isLogin ? handleSubmit(editGuest) : handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'Guest Registration From'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* mobile number */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Mobile no / मोबाइल नंबर      (will be login number)"
                                placeholder="Mobile no / मोबाइल नंबर"
                                type="number"
                                required="required"
                                // disabled={(formData?.contactDetails?.mobileNo && useData?.user?.userType !== 1)}
                                {...register("mobileNo", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    value: `${formData?.mobileNo || ""}`,
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अंक भरें"
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "कृपया 10 अंक से अधिक न भरें"
                                    },
                                    pattern: {
                                        value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                        message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleRegisterMobileNoChange(e)}
                            />
                            {errors.mobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.mobileNo.message}</p>}
                            {isMoblieNumberAlreadyExist && <span className=' animate-ping  w-100 h-100'>🔃 checking</span>}
                            {mobileNumberCheckLoading && <small className=' text-green-700   w-100 h-100'> ✔ Valid Mobile Number</small>}

                        </div>


                        {/* First Name */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Full Name / पूरा नाम"
                                placeholder="Full Name / पूरा नाम"
                                type="text"
                                required="required"
                                {...register("name", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    value: `${formData?.name || ""}`,
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: "कृपया 40 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("name", e)}
                            />
                            {errors.firstName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.firstName.message}</p>}
                        </div>

                        {/* receipt number */}
                        <div className="sm:col-span-3">
                            <Input
                                label="receipt number"
                                placeholder="receipt number"
                                type="text"
                                // required="required"
                                {...register("receiptNumber", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    value: `${formData?.receiptNumber || ""}`,
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: "कृपया 40 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("receiptNumber", e)}
                            />
                            {errors.receiptNumber && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.receiptNumber.message}</p>}
                        </div>

                        {/* currentAddress city */}
                        <div className="sm:col-span-3">
                            <CityDropDown
                                options={cityOptions}
                                setCity={setCityOptions}
                                search={'city'}
                                id={'currentCity'}
                                setOptions={setCurrentCity}
                                required="required"
                                label={"Current city / वर्तमान शहर"}
                                className={' w-full h-9 px-4 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                style={' text-black font-bold'}
                                alredySetCity={currentCity}
                            />
                        </div>

                        <div className="col-span-full">
                            <Textarea
                                label="Current Address / वर्त्तमान पता"
                                placeholder="Current Address / वर्त्तमान पता"
                                type="text"
                                required="required"
                                {...register("currentAddress", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    value: `${formData?.currentAddress}`,

                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "कृपया 100 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("currentAddress", e)}
                            />
                            {errors.currentAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentAddress.message}</p>}

                        </div>


                        {/* <div className="sm:col-span-3">
                            <Input
                                label="Date / एन्ट्री तारीख"
                                placeholder="एन्ट्री तारीख"
                                type="text"
                                required="required"
                                value={new Date().toLocaleString()}
                                disabled

                            />
                            {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>}
                        </div>

                        <div className="col-span-full mx-2 ">

                            <Textarea
                                label="suggesstions / सुझाव"
                                placeholder="suggesstions"
                                type="text"
                                {...register("suggesstions", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    // value: `${formData?.permanentAddress}`,
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 20 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 2000,
                                        message: "कृपया 2000 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("suggesstions", e)}
                            />
                            {errors.suggesstions && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.suggesstions.message}</p>}

                        </div> */}

                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {isLogin ? "Edit" : 'Save'}
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>
                </form>
            </div>
        </>
    )
}
// suggesstions entry id-
