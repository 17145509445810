import React, { useEffect, useLayoutEffect, useState } from 'react'
import { calculateAge, MatrimonyVeiw, MatrimonyViewModel } from '../Components/reshtey';
import { Domain } from '../ApiConfig';
import femaleLogo from "../assets/final cards for mayur/female matromony/femalematriwomenimage.png"
import maleLoga from "../assets/final cards for mayur/male matrimony/male image.png"
import { searchAMtrimonyForShowInSamelan, ShowIntrest } from '../service/Services';
import LoadingModal from '../Forms/LoadingModel';
import Watermark from '../Components/WaterMark';
import { DateFormater } from '../Helper/DateFromt';

export default function VivahGallery({ candidat, setReciverId }) {

    const [isModalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };
    const {
        isVerified, personalInformation, matrimonyCode, occupationDetails, kundliDetails, photos, membershipID, book_id } = candidat
    const { currentCity,
        currentMaritalStatus,
        gotra,
        name
        ,
        heightFeet,
        heightInch,
        gender
    } = personalInformation;
    const { occupation } = occupationDetails
    const { birthDateTime } = kundliDetails
    const { profilePhoto } = photos


    // const isBookIdPresent = (bookId) => {
    //     if (bookId !== "" && bookId !== "no book id" && bookId !== null && !isNaN(bookId)) return '| Book Code :' + bookId;
    //    
    // {isBookIdPresent(book_id)} return
    // }


    return (
        <>

            {/* <MatrimonyViewModel
                isVisible={isModalVisible}
                onClose={closeModal}
                title="Matrimony Profile"
                width="100px"
                height="100px"
            >

                <div className=' w-full flex justify-center items-center'>
                    <button onClick={() => setModalVisible(false)} className=' w-20 rounded-md  font-20  bg-yellow-500 text-black' >Close</button>
                </div>
                
                <ShowProfile matrimonyCodeSearch={matrimonyCode} setCloseProfile={setModalVisible} />

                <div className=' w-full flex justify-center items-center mt-4'>
                    <button onClick={() => setModalVisible(false)} className=' w-20 rounded-md  font-20  bg-yellow-500 text-white' >Close</button>
                </div>
            </MatrimonyViewModel> */}

            {isModalVisible && <ShowProfile matrimonyCodeSearch={matrimonyCode} setCloseProfile={setModalVisible} />}

            <div className='w-[95vw] md:w-[350px] xl:w-[400px]  shadow-2xl shadow-all-sides  rounded-3xl  p-3 h-[100%]  bg-white flex flex-col justify-start'>
                <div className='flex justify-center '>
                    <p className='bg-blue-900 w-100 px-4 xl:px-5 rounded-xl py-[0.2rem] xl:py-1 text-[0.9rem]  xl:text-[1rem] text-[#FFF411] font-semibold'>Profile ID :  {matrimonyCode}  </p>
                </div>
                <div className={` flex mt-2 rounded-t-xl   justify-center text-xl xl:text-[16px] font-bold items-center text-white h-[2rem] xl:h-[2.5rem] w-100 ${(gender == 'Male') ? "metrimony-card-bg-male-head" : "bg-[#EC268F]"}  `} >
                    {name}
                </div>
                <div className={`rounded-b-2xl   relative w-full ${(gender == 'Male') ? "metrimony-card-bg-male-body" : "metrimony-card-bg"}    flex flex-col border-dashed border-gray-500  border-b-2  border-e-2 border-s-2`}>
                    <Watermark text="vaishyaparinay.com" opacity={0.3} fontSize="1.2rem" />

                    <div className='w-full flex justify-center mt-[-1rem] h-[100%]  xl:mt-[0rem] xl:pb-3'>
                        <div className='  w-[30%]    h-full flex items-center '>
                            {/* <img className=' w-28 h-28 xl:w-36 xl:h-36 object-cover' src={gender == 'Male' ? maleLoga : femaleLogo} /> */}
                            <img
                                className='object-cover'
                                src={`${Domain}${profilePhoto}`}
                                loading='lazy'
                            />
                        </div>

                        <div className="  text-[#FFF411] flex-col text-[12px] leading-[16px] xl:leading-[20px] xl:text-[16px] justify-center w-[100%] py-6  md:py-6 xl:py-0   ">
                            <div className=' h-[100%] ms-5 text-[10px] font-bold   xl:ms-7  xl:pt-3'>
                                <div className="flex flex-row justify-between ">
                                    <p className=" w-20 xl:w-40">गोत्र</p>
                                    <p className="w-4/5 metrimony-card-colone">{gotra} </p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">उम्र</p>
                                    <p className="w-4/5 metrimony-card-colone"> {calculateAge(birthDateTime)} वर्ष</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">लंबाई</p>
                                    <p className="w-4/5 metrimony-card-colone"> {heightFeet + '.' + heightInch} feet</p>

                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वर्त्तमान शहर</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {currentCity?.cityName}</p>
                                </div>
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">व्यवसाय</p>
                                    <p className="w-4/5  metrimony-card-colone"> {occupation}</p>
                                </div>
                                {/* <div className="flex flex-row justify-between">
                                    <p className="w-full">पिताजी का व्यवसाय</p>
                                    <p className=" w-4/5 metrimony-card-colone"> {fatherName}</p>
                                </div> */}
                                <div className="flex flex-row justify-between ">
                                    <p className="w-20 xl:w-40">वैवाहिक स्थिति</p>
                                    <p className="w-4/5 metrimony-card-colone"> {currentMaritalStatus}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className=' flex justify-evenly font-bold mt-2'>


                    {isVerified && <button onClick={() => setModalVisible(() => true)} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>View Profile </button>}
                    <button onClick={() => setReciverId(membershipID)} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>Show Interest</button>
                </div>
            </div>
        </>
    )
}


// import React, { useLayoutEffect, useState } from 'react';
// import { searchAMtrimonyForShowInSamelan } from './api'; // assuming the API call is in a separate file
// import LoadingModal from './LoadingModal'; // assuming you have a loading modal component
// import { Domain } from './constants'; // assuming Domain is a constant defined somewhere

const ShowProfile = ({ matrimonyCodeSearch, setCloseProfile }) => {
    const [candidates, setCandidates] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useLayoutEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const requestOptions = {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                };

                const response = await searchAMtrimonyForShowInSamelan(
                    { matrimonyCode: matrimonyCodeSearch },
                    requestOptions
                );

                if (response?.success) {
                    setCandidates(response.data[0] || []);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (matrimonyCodeSearch) fetchData();
    }, [matrimonyCodeSearch]);

    if (isLoading) {
        return <LoadingModal show={isLoading} text="Searching" />;
    }

    if (!candidates) return null;

    const {
        _id,
        userType,
        showMobileNo,
        membershipID,
        matrimonyCode,
        matrimonyActivationStatus,
        marriedStatus,
        personalInformation: {
            name,
            surname,
            samaj,
            gotra,
            skinColor,
            handicapped,
            weightInKG,
            heightFeet,
            heightInch,
            gender,
            currentMaritalStatus,
            currentAddress,
            currentPincode,
            currentCity: { cityID, cityName, stateID: cityStateID, countryID: cityCountryID },
            currentState: { stateID: stateStateID, stateName, stateShortName, countryID: stateCountryID },
            currentCountry: { countryName, countryID: countryCountryID },
            motherTongue,
            languagesKnown,
            bloodGroup
        },
        // divorceDetails: { divorceDate, childrenDetails },
        kundliDetails: { birthDateTime, birthPlace, mangalik },
        educationQualification,
        occupationDetails: { occupation, companyName, designation, occupationCityText, averageSalaryPerMonth },
        contactDetails: { mobileNo, whatsappNo, emailId },
        familyDetail: {
            fatherName,
            fatherOccupation,
            fatherMobileNo,
            fatherMamaGotra,
            motherName,
            motherMamaGotra,
            brother,
            marriedBrother,
            unmarriedBrother,
            sisters,
            marriedSister,
            unmarriedSister
        },
        mamaDetails: { mamaName, mamaGotra },
        // samajReferenceDetails: {
        //     reference1Name,
        //     reference1Occupation,
        //     reference1MobileNo,
        //     reference1Relation
        // },
        othersDetails: { home, wheeler4, wheeler2 },
        // extraInformation: { anyHealthIssues, specialQualities },
        photos: { profilePhoto, personalPhoto1, personalPhoto2 },
        // remarksNotes,
        // Qr,
        // interstRecived,
        // intrestedIn,
        // entryDatetime,
        // updateDate,
        // samajToShow,
        // createdAt,
        // updatedAt,
        // matrimonyEntryId,
        // __v,
        book_id,
        activatedDateTime
    } = candidates;

    return (
        <div className='relative w-full'>


            <div className='is-fixed-top-on-header  top-0 left-0 z-auto w-full h-full shadow-2xl rounded-3xl p-3 bg-white flex flex-col justify-start overflow-auto'>
                <div className='flex justify-center text-center gap-5'>
                    <p className='bg-blue-900 w-full gap-5 max-w-[300px] px-3 sm:px-4 lg:px-5 rounded-xl py-1 text-sm sm:text-base lg:text-lg xl:text-xl text-[#FFF411] font-semibold'>
                        App ID : {matrimonyCode}
                    </p>

                    <p className='bg-blue-900 w-full max-w-[300px] px-3 sm:px-4 lg:px-5 rounded-xl py-1 text-sm sm:text-base lg:text-lg xl:text-xl text-[#FFF411] font-semibold'>
                        Book ID  :{book_id}
                    </p>
                </div>
                <div className={`flex mt-2 md:mt-1 rounded-t-xl justify-center font-bold items-center text-white h-100  w-full ${gender === 'Male' ? 'metrimony-card-bg-male-head' : 'bg-[#EC268F]'}`}>
                    <p className='text-sm sm:text-base py-3 lg:text-lg xl:text-[2.3rem]'>{name}</p>
                </div>
                <div className={`rounded-b-2xl   relative w-full ${gender === 'Male' ? 'metrimony-card-bg-male-body' : 'metrimony-card-bg'} flex flex-col border-dashed border-gray-500 border-b-2 border-e-2 border-s-2`}>
                    <div className='w-full flex-col items-center flex justify-center md:flex-row  mt-[-1rem] xl:mt-[0rem] xl:pb-3'>

                        <div className='w-[90%] mt-5 md:w-[30%] text-center flex flex-col justify-center items-center ms-4'>
                            <figure className=' text-3xl text-[#FFF411] mb-3' >मोबाइल नंबर: {mobileNo}</figure>
                            <img className='object-cover rounded-full w-28 h-28 md:w-full md:h-full' src={`${Domain}${profilePhoto}`} loading='lazy' />
                            <Watermark text="vaishyaparinay.com" opacity={0.3} fontSize="2rem" />

                        </div>
                        <div className="text-[#FFF411] text-[18px] font-bold  flex-col  text-xs sm:text-sm md:text-base lg:text-lg  justify-center w-full py-4 md:py-6 xl:py-0">
                            <div className='h-full ms-3 sm:ms-5 xl:ms-7 pt-2 sm:pt-4'>
                                {/* Personal Info Section */}
                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">जन्म तिथि</p>
                                    <p className="w-[70%] metrimony-card-colone">{new Date(new Date(birthDateTime).getTime() - 19800000).toLocaleDateString('en-GB')} , {DateFormater.getTime(birthDateTime)}</p>
                                </div>
                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">जन्म स्थान</p>
                                    <p className="w-[70%] metrimony-card-colone">{birthPlace}</p>
                                </div>
                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">शिक्षा योग्यता</p>
                                    <p className="w-[70%] metrimony-card-colone">{educationQualification}</p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">लंबाई</p>
                                    <p className="w-[70%] metrimony-card-colone">{heightFeet}.{heightInch} feet</p>
                                </div>



                                <div className="flex justify-between">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">गोत्र</p>
                                    <p className="w-[70%] metrimony-card-colone">{gotra}</p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">व्यवसाय</p>
                                    <p className="w-[70%] metrimony-card-colone">{occupation}</p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">मा.आया</p>
                                    <p className="w-[70%] metrimony-card-colone">{averageSalaryPerMonth || "not provided"} </p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">कार्यो स्थल</p>
                                    <p className="w-[70%] metrimony-card-colone">{occupationCityText}</p>
                                </div>

                                {/* Add more details below */}
                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">पिता का नाम</p>
                                    <p className="w-[70%] metrimony-card-colone">{fatherName}</p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">पिता का व्यवसाय</p>
                                    <p className="w-[70%] metrimony-card-colone">{fatherOccupation}</p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">पता</p>
                                    <p className="w-[70%] metrimony-card-colone">{currentAddress} {stateShortName}</p>
                                </div>


                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">रंग / वज़न</p>
                                    <p className="w-[70%] metrimony-card-colone">{skinColor}-{weightInKG}किलो </p>
                                </div>

                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">मामा गोत्र</p>
                                    <p className="w-[70%] metrimony-card-colone">{mamaGotra} </p>
                                </div>


                                <div className="flex justify-between mt-2 md:mt-1">
                                    <p className="w-[30%] sm:w-[20%] xl:w-[15%]">मांगलिक</p>
                                    <p className="w-[70%] metrimony-card-colone">{mangalik} </p>
                                </div>


                                {/* <div className="flex justify-between mt-2 md:mt-1">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">वर्त्तमान शहर</p>
                                <p className="w-[70%] metrimony-card-colone">{cityName}</p>
                            </div>

                            <div className="flex justify-between mt-2 md:mt-1">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">वैवाहिक स्थिति</p>
                                <p className="w-[70%] metrimony-card-colone">{currentMaritalStatus}</p>
                            </div> */}


                                {/* <div className="flex justify-between mt-2 md:mt-1">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Mother Name</p>
                                <p className="w-[70%] metrimony-card-colone">{motherName}</p>
                            </div>
                            <div className="flex justify-between mt-2 md:mt-1">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Mobile No.</p>
                                <p className="w-[70%] metrimony-card-colone">{mobileNo}</p>
                            </div>
                            <div className="flex justify-between mt-2 md:mt-1">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Email ID</p>
                                <p className="w-[70%] metrimony-card-colone">{emailId}</p>
                            </div>
                            <div className="flex justify-between mt-2 md:mt-1">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Blood Group</p>
                                <p className="w-[70%] metrimony-card-colone">{bloodGroup}</p>
                            </div> */}
                                {/* Continue adding other fields in the same way */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' flex justify-evenly font-bold mt-2 md:mt-1'>
                    {/* <button onClick={openModal} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>View Profile </button> */}
                    <button onClick={() => setCloseProfile(false)} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>Close</button>
                </div>
            </div>
        </div>
    );
};

const ProfileDetail = ({ label, value }) => (
    <div className="flex justify-between mt-2">
        <p className="w-[30%] sm:w-[20%] xl:w-[15%]">{label}</p>
        <p className="w-[70%]">{value}</p>
    </div>
);

// export default ShowProfile;



