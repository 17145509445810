

export const gotra = [
    "मेहता",
    "मांदलिया",
    "मंडवारिया",
    "डबकरा",
    "दानगढ़",
    "सेठिया",
    "काला",
    "मुजावदिया",
    "चौधरी",
    "धनोतिया",
    "संघवी",
    "घाटिया",
    "मुन्या",
    "घरिया",
    "रत्नावत",
    "फरक्या",
    "वेद",
    "उड़िया",
    "भेसोटा",
    "भूत",
    "नभेपुरिया",
    "श्रीखंडिया",
    "कामरिया"
];
// Manually created array of salaries from 15,000 to 10,00,000
export const salaries = [
    "15000",   // 15,000
    "25000",   // 25,000
    "35000",   // 35,000
    "45000",   // 45,000
    "55000",   // 55,000
    "65000",   // 65,000
    "75000",   // 75,000
    "85000",   // 85,000
    "95000",   // 95,000
    "100000",  // 1,00,000
    "150000",  // 1,50,000
    "200000",  // 2,00,000
    "300000",  // 3,00,000
    "400000",  // 4,00,000
    "500000",  // 5,00,000
    "600000",  // 6,00,000
    "700000",  // 7,00,000
    "800000",  // 8,00,000
    "900000",  // 9,00,000
    "1000000"  // 10,00,000
];

export const userAcess = ['Admin', 'Matrimony', 'Normal Member', 'Guest']




export const years = [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081, 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099, 2100];
export const occupations = [
    "Family Business",
    "Private Job",
    "Government Job",
    'Housewife',
    'Lawyer',
    'Doctor',
    'Chartered Accountant',
    'Company Secretary',
    'Army professional',
    'Police Services',

    'self Employed',
    'No Job',
    "Other"
]

export const currentMarritalStatus = [
    'Unmarried', 'Remarriage', 'Widow', 'Single', 'Divorcee'
]

// export const vaishaSamajAndGotra = {
//     'पोरवाल जांगड़ा': [
//         "मेहता",
//         "मांदलिया",
//         "मंडवारिया",
//         "डबकरा",
//         "दानगढ़",
//         "सेठिया",
//         "काला",
//         "मुजावदिया",
//         "चौधरी",
//         "धनोतिया",
//         "संघवी",
//         "घाटिया",
//         "मुन्या",
//         "घरिया",
//         "रत्नावत",
//         "फरक्या",
//         "वेद",
//         "उड़िया",
//         "भेसोटा",
//         "भूत",
//         "नभेपुरिया",
//         "श्रीखंडिया",
//         "कामरिया"
//     ],
//     'माहेश्वरी': ['माहेश्वरी-1', 'माहेश्वरी-2', 'माहेश्वरी-3', 'vaishay-5', 'vaishay-6', 'vaishay-7', 'vaishay-8', 'vaishay-9', 'vaishay-10',],
// }

export const vaishaSamajAndGotra = {
    'अन्य वैश्य समाज': ['अन्य वैश्य समाज गोत्र'],
    'अग्रवाल': ["अन्य गोत्र", "ऐरन", "गोयल", "बंसल", "बिंदल", "भंडाल", "धरण", "गर्ग", "गोयनका", "गोयं", "गंगल", "जिंदल", "कंसल", "कुच्चाल", "मधुकुल", "मुद्गल", "मंगल", "मित्तल", "नंगल", "सिंघल", "तायल", "तिंगल", "सिंगला",],
    'पोरवाल जांगड़ा': ["अन्य गोत्र", "मेहता", "मांदलिया", "मंडवारिया", "डबकरा", "दानगढ़", "सेठिया", "काला", "मुजावदिया", "चौधरी", "धनोतिया", "संघवी", "घाटिया", "मुन्या", "घरिया", "रत्नावत", "फरक्या", "वेद", "उड़िया", "भेसोटा", "भूत", "नभेपुरिया", "श्रीखंडिया", "कामरिया"],
    'माहेश्वरी': ["अन्य गोत्र", "अजमेरा", "असावा", "अट्टल", "आगीवाल", "आगसूड", "ईनाणी", "करवा", "कांकाणी", "काहालिया", "कलंत्री", "कासट", "कचोलिया", "कालानी", "काबरा", "कालिया", "खटवड़", "गगराणी", "गट्टानी", "गदईया", "गिलड़ा", "चांडक", "चौखड़ा", "चेचाणी", "छापरवाल", "जाजू", "जाखेटिया", "झंवर", "टावरी", "डाड", "डागा", "तोषणीवाल", "तोतला", "तापड़िया", "दरक", "दरगड़", "देवपुरा", "धूत", "धूपड़", "नवाल", "नौलखा", "न्याति", "नावंधर", "फलौड", "परताणी", "परवाल", "पौरवाल", "बलदुआ", "बाहेती", "बालदी", "बूब", "बांगड़", "बिहाणी", "बिदादा", "बजाज", "बिड़ला", "बंग", "भंडारी", "भट्टड़", "भूतड़ा", "भूराड़िया", "भंसाली", "मालू", "मालपानी", "मानधन्या", "मूंधड़ा", "मंडोवरा", "मणियार", "मोदानी", "मंत्री", "रांधड़", "राठी", "लड्डा", "लाहौटी", "लाखोटिया", "लोहिया", "सोनी", "सोमानी", "सोढानी", "सारडा", "सिकची", "हुरकट", "हेडा",
        "मालानी", "लाठी", "लोया", 'हेड़ा', "मुच्छाल", "मंडोरा", "मोहता", "नंगवालिआ", "बटवाल", "साबू", "भांगड़िया", "डोडिया", "कोठारी", "केला", "खाबाणी", "धूमरान्स", "भांगडिया", "भक्कड़", "खटोड़", "पसारी", "लददार", "थेपड़िया", "गोरानी", "समदानी", "बिसानी", "साबू", "कश्यप"],
    'खंडेलवाल': ["अन्य गोत्र", "अटोरिया", "आकड", "आमेरिया", "ओढ", "कट्टा", "कठोरिया", "काइथवार", "काठ", "कासलीवार", "किलकिलिया", "कूलवार", "केदावत", "कोडिया", "खारवाल", "खूंटेटा", "गोंद्राजिया", "घीया", "जसोरिया", "जघींनिया", "झालानी", "टटार", "ठाकुरिया", "डंगायच", "डाँस", "तमोलिया", "ताम्बी", "तेाडकर", "दुसाद", "धामानी", "धोकरिया", "नारायणवार", "नाटानी", "नैनामा", "नैनीवार", "पचलौरा", "पगूवाल", "पाटोदिया", "पीतलिया", "फरसोइया", "बटवारा", "बडाइया", "बुडवारिया", "वूसर", "भंडारिया", "भांगला", "भुकमारिया", "महरवाल", "माठा", "मनिक", "बोहरा", "मामोडिया", "माली", "मँगोलिया", "मांचीवाल", "मेठी", "राजोरिया", "रावत", "लाभी", "वैद", "साखोरिया", "सामरिया", "साहरिया", "सिरोहिया", "संगोदिया", "सेठी", "सौंखिया", "हल्दिया", "बनाउडी", "बम्ब", "बजरगान", "बावरिया", "बीमवार", "बढहरा", "कानूनगो", "कायथवाल", 'ताम्बी', 'सिरोया'],

    'दिगंबर जैन': [
        "अन्य गोत्र", 'खंडेलवाल', 'परवर', 'हुमड़', 'पोरवाड़', 'गोलालरे', 'गोलापुरवा', "फुसकेले", "बड़जात्या", "बाझल", "नगदोल", "डेरिया मूर बसल", "बाला मुरी", "गांगरे मूरि", "कमलेश्वर", "धनमूरि कंसल", "पाटनी", "रतिया मूरि", "पहाड़िया", "धवल धुरंदर", "डेरिया मूर बासल", "बरोद", "मेटवाल", "अंतरसमन", "भटकेश्वर", "पालिया", "कुम्हारिया", "बोहरा", "वात्सल्य", "दिवाकिर्ति", "प्रजापत", "कपासिया", "फडनिस", "जटाले", "सेठी", "जोहिला", "झांझरी", "अजमेरा", "गंगेश्वर", "भोगावत", "खटोड़", "गोयल", "बड़कुल", "कश्यप", "पड़ेले", "लक्ष्यपति", "पहाड़िया", "गंगवाल", "पाटोदी", "पदमावती पोरवाल", "विनायका", "गोधा", "भंडारी", "बज", "सेठिया", "ओरा", "पल्लीवाल जैन", "पाण्डे", "राजोरिया", "ठाकुरिया", "भारूमुरी भारील्या", "बंजारे", "अंबरेश्वर", "भद्रेश्वर",
        "खेरजू", "गोलापुरवा", "गोइला", "इहादिम मुरी बांझल", "कासलीवाल", "पोरवाड",
        "पाटनी", "शाह", "छाबरा", "भारूमुरी बांझल", "फुलफ्फगर", "सरवछोला मुरी",
        "रजीयाण", "पोरवार", "टोगया", "बाकलीवाल", "विजाणु", "विनायका", "गोयल",
        "फंगुल", "मंत्रेश्वर", "सामरिया", "चंदोरिया", "पदमावती मूरी बांझल",
        "खात्म मूरी", "बाकलीवाल", "वज्जनू", "सोगानी", "रकियामुरी बांझल",
        "चौधरी", "उत्तरेश्वर", "रावंका", "गर्ग अग्रवाल", "धवल", "खेरजा",
        "बागड़िया", "डेडामूरी बांसलगोटी", "सेठिया बाघेशवर", "तासरिया",
        "कंजोलिया", "बैसाखिया गोयल", "बिलाला", "बरोड़", "भद्रेश्वर",
        "काला",
        "पांड्या",
        "छाबड़ा",
        "प्रथम मूरी",
        "सांघैलिया",
        "आतरेश्वर"
    ],

    'श्वेताम्बर जैन': ["अन्य गोत्र", "तातेड़", "बाफना", "कर्नाट", "बलहारा", "मोराक्ष", "आईचनाक", "भूरी", "भटेवड़ा", "भादर", "कुलहट", "बिरहट", "संचेती", "श्रीश्रीमाल", "चींचट", "कूमट", "डीडू", "श्रेष्टी", "लघुश्रेष्टि", "नाहर", "तरवेचा", "धींग", "बोबावत", "नांदेचा", "चोरडिया", "चडालिया", "बुद्धेश्वर", "खुदबाठिया", "पटवारी", "फाफरिया", "लुनावत", "ललवानी", "सुराना", "बांठिया", "पितलिया", "चोपड़ा", "पोरवाल", "नलवाया", "चेलावत", "सुरपारिया", "बीसा पोरवाल", "बोहरा", "भंडारी", "दरदा", "तलेरा", "बोरदीया", "चत्तर", "सिरोलिया", "विरानी", "तवरेचा", "कोठारी", "नपवालिया", "लुटावत", "मोथसरा", "भंडारी", "मुनोट", "लोधा", "चांडल्या", "वेद मेहता", "विनायिका",
        "पोरवाल", "डूॅंगरवाल", "मेहता", "छजलानी", "मालू", "लहरोट", "करैया", "कांठडे",
        "चाणोदिया", "मंडलेचा", "चोपड़ा", "मारू", "सालेंचा", "नाहर", "कुकड़ा", "कोठारी",
        "चोरड़िया", "संचेती", "नाहटा", "सालेचा", "चैहान", "मेहता (कोचर)", "कटारिया",
        "छाजेड़", "मण्डलेचा(सोनी)", "कांठेड", "विनयायक्या", "चंद्रावत", "बांझल (देशमुनी)",
        "भूताला", "सोनल", "माण्डलिक", "लोढ़ा", "चैराड़िया", "चाणोदिया", "कश्यप यादव",
        "भण्डारी", "श्रीश्रीमाल", "औरा", "नांदेचा", "टाटिया", "बाकना", "शाह",
        "बीसी पोरवाल", "गुगले", "बोरा", "गलेडा", "भंडारी", "लोढा", "लसोड़", "परवा",
        "पोखरना", "गोखरू", "बागरेचा", "बाठिया", "मेहता", "गोलेच्छा", "खटोड़ा",
        "बीसी पोरवाल", "गेलड़ा", "भंडारी", "मोदी (पावेचा)", "सुराणा", "धुपिया(किमती)",
        "कंकू चोपड़ा", "सालेचा व्होरा", "धाटोलिया", "रांका", "लुणावत", "मुणत",
        "छिंगावत", "वोरा", "पारेख", "धाकड", "डांगी", "मेहता", "हरवाड़ी", "घाटावत",
        "दुग्गड़", "कितनावद", "हर्षगोधा", "कोलन", "मेहता (बोथरा)",
        "डांगी", "लोढा", "गांधी", "तातेड", "वोरा", "डोसी", "सकलेचा", "हरवाडिया",
        "चैधरी", "कोचर", "वागरेचा", "धाडीवाल", "कांकरिया", "सरवाडी", "चैधरी",
        "नागोरी", "मांडोत", "छाजेड", "जोधावत", "ललवानी", "बाफना", "कटारिया",
        "मेहता", "वेदमूथा", "ढाबरिया", "कौल", "सोनावत", "कटारिया", "पिरोदिया",
        "नाहटा", "नांदेचा", "मेहता(हल्दी)", "कुंडल", "कंकु चोपड़ा", "सालेचा",
        "पामेच", "नवलखा", "मारू", "गुढका", "चोरडिया", "छाजेड़", "कापडिया",
        "पोरवाल", "ओरा", "भंडारी", "रांका", "तातेड़ा", "छाजेड", "टांटिया",
        "सालगिया", "कोचर", "बंम", "गोलेच्छा", "चोरड़िया", "झेलावत", "कौल",
        "रूणवाल", "त्रितोदिया", "झेलावत", "कचोलिया", "कुंडल", "झांझरिया",
        "कितनावद", "बाफना", "सुराना", "पिपाडा", "नांदेचा", "खटोड़", "डांगी",
        "लुणिय", "बाफना", "कोठारी (रणधरोत)", "मोदी (बड़वाना)", "गाॅंधी", "शाह",
        "माण्डलिक", "नायक", "सकलेचा", "चैरडिया", "गांधी", "मेहता", "मूणत",
        "वेद मेहता", "सुराणा", "शेखावत", "पटवा", "सालेचा", "पिछोलिया",
        "खटोड़ा", "संघवी", "पालरेचा", "सियाल", "लुनावत", "कर्नावट", "सुराणा",
        "छाजेड़", "मेहता वेद", "तेजावत", "वेद मेहता", "सोनावत", "बरड़िया",
        "कोचर", "बरमेचा", "लूनावत", "मंडलिक", "मारू", "सालेचा", "सिसोदिया",
        "खिमेसरा", "धींग", "दुगड़ा"
    ],
    'गहोई वैश्य': ["अन्य गोत्र", 'वासर', 'गोल', 'गांगल', 'बादल', 'कोच्छल', 'जैतल', 'वाच्छिल', 'काछिल', 'भाल', 'कोहिल', 'कासिव', 'सिंगल'],
    'विजयवर्गीय': ["अन्य गोत्र", "अधेड़िया", "अजलोड़िया", "अजमेरा", "अजमेरा सिंगी", "बाढड़ारा", "बाहरदिया", "बाँधीवाल", "बांदीवल", "बंसीवाल", "बंथलिवल", "बाथ्रा", "बौड़ादा", "बेंकटा", "भरडलिया", "भियन्या", "बोहरा", "बोरा", "चौधरी", "ढोसीवाल", "दुसांज", "गढ़", "ग़जनीवाल", "गराउ", "घरसवल", "घीया", "गोठडीवाल", "ग्वालेरिया", "हिंगवा", "झोझूटा", "कापड़ी", "कटारिया", "खण्डारिया", "खंडया", "खाण्डला", "खेंतोदया", "खोहवाल", "खूंटेटा", "कोडई", "लुहारिया", "माली", "मनिहार", "मरोतिया", "मुवानिया", "नैवाल", "नराणीवाल", "नरेडीवाल", "नाट्य", "नायकवाल", "निजरेंया", "पंचालिया", "पंचालुहाड़िया", "पंचोली", "पर्दा", "परवा", "पटनिवल", "पाटोदिया", "फगवाल", "पोलीवाल", "राजोरिया", "सागरीय", "सागरीय", "सेंधानी", "सिंघवान", "सिंगी", "सहेलिया", "सुरल्या", "तमोली", "टोकरवासा", "ऊंची", "वहतारा", "वरदा"],
    'मेड़तवाल': ["अन्य गोत्र", "भंडारी", "दाख", "बोकड़ा", "घाटिया", "बोबस", "सेठिया", "टांक", "करोड़िया", "पादवा", "जुलानिया", "सोनी", "कपास्या", "रोंडिया", "नकवाल", "उमरतिया", "आचैलिया", "बरोंज", "शुक्रवारिया", "सारसण्डया", "माल्या", "मूंजा", "फोफलिया", "पगारिया", "मोड़ीवाल", "मोगिया (मोघा)", "कुकस्या", "हींगड़", "तेली", "बूंबा", "राजकुराड़", "गांधी", "तोदिया", "उमट्टिया", "समवेगी", "पड़लोदा", "भगनीघ", "गलीय", "वमलीय", "माणधरा", "बेहड़ा", "लुलानिया", "इंद्राणी", "रड़वट", "झांझर झार सड़क", "तुड़क्या", "लड़हड़", "मुगडण्हा", "लालण", "झंडोदिया", "नरवरा मोया", "जाजू",
        "भरण्डया"],
    'मोड माण्डलिया': ["अन्य गोत्र", 'कटारिया', 'मोड माण्डलिया-1', 'मोड माण्डलिया-2', 'मोड माण्डलिया-3', 'मोड माण्डलिया-4', 'मोड माण्डलिया-5', 'मोड माण्डलिया-6', 'मोड माण्डलिया-7', 'मोड माण्डलिया-8', 'मोड माण्डलिया-9', 'मोड माण्डलिया-10'],
    'नागर चित्तोड़ा': ["अन्य गोत्र", 'नागर चित्तोड़ा-1', 'नागर चित्तोड़ा-2', 'नागर चित्तोड़ा-3', 'नागर चित्तोड़ा-4', 'नागर चित्तोड़ा-5', 'नागर चित्तोड़ा-6', 'नागर चित्तोड़ा-7', 'नागर चित्तोड़ा-8', 'नागर चित्तोड़ा-9', 'नागर चित्तोड़ा-10'],
    'दृढ़ोमर': ["अन्य गोत्र", 'दृढ़ोमर-1', 'दृढ़ोमर-2', 'दृढ़ोमर-3', 'दृढ़ोमर-4', 'दृढ़ोमर-5', 'दृढ़ोमर-6', 'दृढ़ोमर-7', 'दृढ़ोमर-8', 'दृढ़ोमर-9', 'दृढ़ोमर-10'],
    'अग्रहरि वैश्य': ["अन्य गोत्र", 'अग्रहरि वैश्य-1', 'अग्रहरि वैश्य-2', 'अग्रहरि वैश्य-3', 'अग्रहरि वैश्य-4', 'अग्रहरि वैश्य-5', 'अग्रहरि वैश्य-6', 'अग्रहरि वैश्य-7', 'अग्रहरि वैश्य-8', 'अग्रहरि वैश्य-9', 'अग्रहरि वैश्य-10'],
    'लाड': ["अन्य गोत्र", 'लाड-1', 'लाड-2', 'लाड-3', 'लाड-4', 'लाड-5', 'लाड-6', 'लाड-7', 'लाड-8', 'लाड-9', 'लाड-10'],
    'गंगराड़े पोरवाल': ["अन्य गोत्र", "क्षीरसागर", "बोंदरिया", "मेथा", "बोराडिया", "लखोंडिया", "गोगांवा",
        "कवजा", "पटोलिया", "बोरानिया", "सिरलोया", "डोलिया", "सोनारिया",
        "बछानिया", "मोदलिया", "भगवतिया", "मोदी", "पितांबरिया", "बोराया",
        "भंडारी", "कातरिया", "बीरबंद", "सोनी", "बरईया", "लकटकिया",
        "साडिया", "मथुरिया"],
    'पोरवाल पुरवार': ["अन्य गोत्र", 'पोरवाल पुरवार-1', 'पोरवाल पुरवार-2', 'पोरवाल पुरवार-3', 'पोरवाल पुरवार-4', 'पोरवाल पुरवार-5', 'पोरवाल पुरवार-6', 'पोरवाल पुरवार-7', 'पोरवाल पुरवार-8', 'पोरवाल पुरवार-9', 'पोरवाल पुरवार-10'],
    'विशा नागर वणिक': ["अन्य गोत्र", 'विशा नागर वणिक-1', 'विशा नागर वणिक-2', 'विशा नागर वणिक-3', 'विशा नागर वणिक-4', 'विशा नागर वणिक-5', 'विशा नागर वणिक-6', 'विशा नागर वणिक-7', 'विशा नागर वणिक-8', 'विशा नागर वणिक-9', 'विशा नागर वणिक-10'],
    'माथुर वैश्य': ["अन्य गोत्र", 'माथुर वैश्य-1', 'माथुर वैश्य-2', 'माथुर वैश्य-3', 'माथुर वैश्य-4', 'माथुर वैश्य-5', 'माथुर वैश्य-6', 'माथुर वैश्य-7', 'माथुर वैश्य-8', 'माथुर वैश्य-9', 'माथुर वैश्य-10'],
    'नीमा': ["अन्य गोत्र", 'नीमा-1', 'नीमा-2', 'नीमा-3', 'नीमा-4', 'नीमा-5', 'नीमा-6', 'नीमा-7', 'नीमा-8', 'नीमा-9', 'नीमा-10'],
    'दशा नीमा': ["अन्य गोत्र", "ख़दारिया", "पटवारी", "गलवाना", "महाजन", "बालानंदन", "सेठ", "कश्यप", "खीरा", "कटलाना", "धींगाणा", "चंचावत"]
};

export const vaishaSamaj = [
    'अन्य वैश्य समाज',
    'अग्रवाल',
    'माहेश्वरी',
    'खंडेलवाल',
    'दिगंबर जैन',
    'श्वेताम्बर जैन',
    'गहोई वैश्य',
    'विजयवर्गीय',
    'मेड़तवाल',
    'मोड माण्डलिया',
    'नागर चित्तोड़ा',
    'दृढ़ोमर',
    'अग्रहरि वैश्य',
    'लाड',
    'गंगराड़े पोरवाल',
    'पोरवाल जांगड़ा',
    'पोरवाल पुरवार',
    'विशा नागर वणिक',
    'माथुर वैश्य',
    'नीमा',
    'दशा नीमा',
]

export const ageRange = ['18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '32', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']


// Doctor
// Chartered Accountant
// Company Secretary
// Lawyer
// Army professional
// Police Services


// past
// [
//     'माहेश्वरी',
//     'खंडेलवाल',
//     'दिगंबर जैन',
//     'श्वेताम्बर जैन',
//     'गहोई वैश्य',
//     'विजयवर्गीय',
//     'मेड़तवाल',
//     'मोड माण्डलिया',
//     'नागर चित्तोड़ा',
//     'दृढ़ोमर',
//     'अग्रहरि वैश्य',
//     'लाड',
//     'गंगराड़े पोरवाल',
//     'पोरवाल जांगड़ा',
//     'पोरवाल पुरवार',
//     'विशा नागर वणिक',
//     'माथुर वैश्य',
//     'नीमा']
// *********************************************************

// पोरवाल
// डूॅंगरवाल
// मेहता
// छजलानी,
// मालू,
// लहरोट,
// करैया
// कांठडे,
// चाणोदिया,
// मंडलेचा,
// चोपड़ा
// मारू,
// सालेंचा,
// नाहर,
// कुकड़ा
// कोठारी,
// चोरड़िया
// संचेती,
// नाहटा
// सालेचा,
// चैहान,
// मेहता (कोचर),
// कटारिया, छाजेड़,
// मण्डलेचा(सोनी), कांठेड,
// विनयायक्या,
// चंद्रावत, बांझल (देशमुनी),
// भूताला, सोनल,
// माण्डलिक, लोढ़ा,
// चैराड़िया, चाणोदिया,
// कश्यप यादव,
// भण्डारी,श्रीश्रीमाल,
// औरा, नांदेचा,
// टाटिया, बाकना,
// शाह,
// बीसी पोरवाल,
// गुगले, बोरा,
// गलेडा, भंडारी,
// लोढा, लसोड़,
// परवा, पोखरना,
// गोखरू, बागरेचा,
// बाठिया, मेहता,
// गोलेच्छा, खटोड़ा,
// बीसी पोरवाल,
// गेलड़ा, भंडारी,
// मोदी (पावेचा) सुराणा,
// धुपिया(किमती) कंकू चोपड़ा,
// सालेचा व्होरा, धाटोलिया,
// रांका, लुणावत,
// मुणत, छिंगावत,
// वोरा, पारेख,
// धाकड, डांगी,
// मेहता, हरवाड़ी,
// घाटावत, दुग्गड़,
// कितनावद, हर्षगोधा,
// आॅचलिया (कोठारी), कोलन,
// मेहता (बोथरा) डांगी,
// लोढा, गांधी,
// तातेड, वोरा,
// डोसी, सकलेचा,
// हरवाडिया, चैधरी,
// कोचर, वागरेचा,
// धाडीवाल, कांकरिया,
// सरवाडी, चैधरी,
// नागोरी, मांडोत,
// छाजेड, जोधावत,
// ललवानी बाफना,
// कटारिया, मेहता,
// वेदमूथा, ढाबरिया,
// कौल,सोनावत,
// कटारिया, पिरोदिया,
// नाहटा, नांदेचा,
// मेहता(हल्दी),कुंडल,
// कंकु चोपड़ा,सालेचा,
// पामेच,नवलखा,
// मारू,गुढका,
// चोरडिया,छाजेड़,
// कापडिया, पोरवाल,
// ओरा, भंडारी,
// रांका,तातेड़ा,
// छाजेड,टांटिया,
// सालगिया,
// कोचर, बंम,
// गोलेच्छा, चोरड़िया,
// झेलावत, कौल,
// रूणवाल,
// त्रितोदिया,झेलावत,
// कचोलिया,कुंडल,
// झांझरिया, कितनावत,
// बाफना, सुराना,
// पिपाडा,नांदेचा,
// खटोड़,डांगी,
// लुणिय, बाफना,
// कोठारी (रणधरोत), मोदी (बड़वाना),
// गाॅंधी,शाह,
// माण्डलिक,नायक,
// सकलेचा,
// चैरडिया,गांधी,
// मेहता,मूणत,
// वेद मेहता,सुराणा,
// शेखावत, पटवा,
// सालेचा,पिछोलिया,
// खटोड़ा, संघवी,
// पालरेचा,सियाल,
// लुनावत, कर्नावट,
// सुराणा,छाजेड़,
// मेहता वेद,तेजावत,
// वेद मेहता,सोनावत,
// बरड़िया,कोचर, बरमेचा,
// लूनावत, मंडलिक,
// मारू, सालेचा,
// सिसोदिया, खिमेसरा,
// धींग,दुगड़ा,

// "पोरवाल", "डूॅंगरवाल", "मेहता", "छजलानी", "मालू", "लहरोट", "करैया", "कांठडे",
//     "चाणोदिया", "मंडलेचा", "चोपड़ा", "मारू", "सालेंचा", "नाहर", "कुकड़ा", "कोठारी",
//     "चोरड़िया", "संचेती", "नाहटा", "सालेचा", "चैहान", "मेहता (कोचर)", "कटारिया",
//     "छाजेड़", "मण्डलेचा(सोनी)", "कांठेड", "विनयायक्या", "चंद्रावत", "बांझल (देशमुनी)",
//     "भूताला", "सोनल", "माण्डलिक", "लोढ़ा", "चैराड़िया", "चाणोदिया", "कश्यप यादव",
//     "भण्डारी", "श्रीश्रीमाल", "औरा", "नांदेचा", "टाटिया", "बाकना", "शाह",
//     "बीसी पोरवाल", "गुगले", "बोरा", "गलेडा", "भंडारी", "लोढा", "लसोड़", "परवा",
//     "पोखरना", "गोखरू", "बागरेचा", "बाठिया", "मेहता", "गोलेच्छा", "खटोड़ा",
//     "बीसी पोरवाल", "गेलड़ा", "भंडारी", "मोदी (पावेचा)", "सुराणा", "धुपिया(किमती)",
//     "कंकू चोपड़ा", "सालेचा व्होरा", "धाटोलिया", "रांका", "लुणावत", "मुणत",
//     "छिंगावत", "वोरा", "पारेख", "धाकड", "डांगी", "मेहता", "हरवाड़ी", "घाटावत",
//     "दुग्गड़", "कितनावद", "हर्षगोधा", "आॅचलिया (कोठारी)", "कोलन", "मेहता (बोथरा)",
//     "डांगी", "लोढा", "गांधी", "तातेड", "वोरा", "डोसी", "सकलेचा", "हरवाडिया",
//     "चैधरी", "कोचर", "वागरेचा", "धाडीवाल", "कांकरिया", "सरवाडी", "चैधरी",
//     "नागोरी", "मांडोत", "छाजेड", "जोधावत", "ललवानी", "बाफना", "कटारिया",
//     "मेहता", "वेदमूथा", "ढाबरिया", "कौल", "सोनावत", "कटारिया", "पिरोदिया",
//     "नाहटा", "नांदेचा", "मेहता(हल्दी)", "कुंडल", "कंकु चोपड़ा", "सालेचा",
//     "पामेच", "नवलखा", "मारू", "गुढका", "चोरडिया", "छाजेड़", "कापडिया",
//     "पोरवाल", "ओरा", "भंडारी", "रांका", "तातेड़ा", "छाजेड", "टांटिया",
//     "सालगिया", "कोचर", "बंम", "गोलेच्छा", "चोरड़िया", "झेलावत", "कौल",
//     "रूणवाल", "त्रितोदिया", "झेलावत", "कचोलिया", "कुंडल", "झांझरिया",
//     "कितनावद", "बाफना", "सुराना", "पिपाडा", "नांदेचा", "खटोड़", "डांगी",
//     "लुणिय", "बाफना", "कोठारी (रणधरोत)", "मोदी (बड़वाना)", "गाॅंधी", "शाह",
//     "माण्डलिक", "नायक", "सकलेचा", "चैरडिया", "गांधी", "मेहता", "मूणत",
//     "वेद मेहता", "सुराणा", "शेखावत", "पटवा", "सालेचा", "पिछोलिया",
//     "खटोड़ा", "संघवी", "पालरेचा", "सियाल", "लुनावत", "कर्नावट", "सुराणा",
//     "छाजेड़", "मेहता वेद", "तेजावत", "वेद मेहता", "सोनावत", "बरड़िया",
//     "कोचर", "बरमेचा", "लूनावत", "मंडलिक", "मारू", "सालेचा", "सिसोदिया",
//     "खिमेसरा", "धींग", "दुगड़ा"