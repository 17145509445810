import React, { useEffect, useRef, useState } from 'react'
import useIntersectionObserver from '../../Helper/useIntersectionObserver';
import { useDispatch, useSelector } from 'react-redux';
import { bookPrint, getMatrimony } from '../../service/Services';
import { setPagination } from '../../Redux/paginationSlicer';
import axios from 'axios';
import { FormHeading } from '../../Forms';
import { useReactToPrint } from 'react-to-print';
import { Domain } from '../../ApiConfig';
import { Pagination } from './userManagement';
import LoadingModal from '../../Forms/LoadingModel';
import { SearchCriteria, SelectInput } from '../../FormsInputes.js';
import { vaishaSamaj } from '../../constants/staticData.js';
import DateInputCriteria from '../../FormsInputes.js/DateInputCriteria.js';
import { DateFormater } from '../../Helper/DateFromt.js';
import Watermark from '../../Components/WaterMark.js';


const downloadImage = async (imageSrc, imageName) => {
    try {
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);  // Clean up after the download
    } catch (err) {
        console.error('Error downloading image:', err);
    }
};
const currentMarritalStatus = [
    'Unmarried', 'Remarriage', 'Widow', 'Single', 'Divorcee'
]

export default function BookPrint() {

    const [currentPage, setCurrentPage] = useState(1);
    const [criteria, setCriteria] = useState({ name: "", surname: "", currentMaritalStatus: "", mobileNo: "", activationAfteDate: "", samaj: "", gotra: "", cityName: '', cityId: '', occupation: "", gender: "", minAge: "", maxAge: "" })
    const [byName, setByName] = useState([])
    const [city, setCity] = useState([])
    // const [occupation, setOccupation] = useState([])
    const [surename, setSurname] = useState([])
    // const [gotra, setGotra] = useState(gotra)
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [members, setMember] = useState([]);
    const [isDownloadBtnOpen, setIsDownloadBtnOpen] = useState(false);
    const [samaj, Setsamaj] = useState(vaishaSamaj);

    // const [paginationData, setPagination] = useState({})
    const [totalPages, setTotalPages] = useState(0);

    const paginationData = useSelector(state => state?.pagination?.pagination)
    const lastItemRef = useIntersectionObserver({ currentPage, paginationData, setCurrentPage });
    const totalItemInPage = 100;

    const dispatch = useDispatch();
    const printReport = useRef(null);

    useEffect(() => {
        setLoading(true)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                const { name, surname, gotra, gender, samaj, minAge, maxAge, occupation, currentMaritalStatus, mobileNo, activationAfteDate, ...cityData } = criteria;

                // console.log('criteria', criteria)
                const memberData = await bookPrint({ name, surname, samaj, currentMaritalStatus, mobileNo, gotra, activationAfteDate, gender, minAge, maxAge, occupation, city: cityData?.city?.id }, requestOptions)
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);

                if (memberData?.success) {
                    const data = memberData?.data || [];
                    setTotalPages(memberData?.pagination?.totalPages);
                    setPagination(memberData?.pagination)
                    dispatch(setPagination(memberData?.pagination))
                    // storePagination(memberData?.pagination)
                    // console.log(memberData?.pagination)
                    setMember([...data])
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [currentPage, criteria])
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrint = useReactToPrint({
        content: () => printReport.current,
        documentTitle: `Print Profiles ${criteria?.samaj} ${criteria?.gotra} ${criteria?.currentMaritalStatus} page ${currentPage}`,
        scale: 0.8,  // Reduce document scale
        onBeforePrint: () => {
            // Remove heavy background styles, images, or elements
            document.querySelectorAll('.no-print').forEach(el => el.style.display = 'none');
    
            // Load low-res images for print
            document.querySelectorAll('img').forEach(img => {
                img.src = img.src.replace('high-res', 'low-res');
            });
        },
        onAfterPrint: () => {
            // Restore original images after print
            document.querySelectorAll('img').forEach(img => {
                img.src = img.src.replace('low-res', 'high-res');
            });
    
            // Restore hidden elements
            document.querySelectorAll('.no-print').forEach(el => el.style.display = '');
        },
        removeAfterPrint: true,
    });
    
    // const handlePrint = useReactToPrint({
    //     documentTitle: "Print This Document",
    //     scale: 0.8,  // Reduce document scale
    //     onBeforePrint: () => console.log("before printing..."),
    //     onAfterPrint: () => console.log("after printing..."),
    //     removeAfterPrint: true,
    // });


    return (
        <>
            <LoadingModal show={isLoading} text={'Loading...'} />
            <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">
                <div className=' ' >
                    <label className={`  block capitalize font-extrabold text-sm  leading-6 text-gray-900`}>
                        samaj
                    </label>
                    <div className="mt-1">
                        <select
                            onChange={(e) => setCriteria(pre => ({ ...pre, samaj: e.target.value }))}
                            className={`block   px-2 w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                        >
                            {samaj.map((option) => (
                                <option value={option} key={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="   ">
                    <DateInputCriteria
                        search="activationAfteDate"
                        id="activationDate"
                        setOptions={setCriteria}
                        label="Activation After Date"
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}
                    />
                </div>
                <div className="   ">


                    {/* name */}
                    <SearchCriteria
                        options={currentMarritalStatus}
                        search={'currentMaritalStatus'}
                        id={'currentMaritalStatus'}
                        setOptions={setCriteria}
                        label={"Current Marital Status"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}
                    />
                </div>

                {/* Gender */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={["Male", "Female"]}
                        search={'gender'}
                        id={'gender'}
                        setOptions={setCriteria}
                        label={"Gender"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* mobileNo */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={[]}
                        search={'mobileNo'}
                        id={'mobileNo'}
                        setOptions={setCriteria}
                        label={"मोबाइल नंबर"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

            </div>
            {/* <div className=' w-screen flex items-center justify-center my-4'>
                <button className='text-white font-bold px-3 py-1 rounded-xl bg-blue-950' onClick={() => setIsDownloadBtnOpen(pre => !pre)}>Show Download Button</button>
            </div> */}
            <div ref={printReport} className=' w-full bg-green-100   flex flex-wrap gap-1 '>
                {/* {members.length != 0 && <MyTable data={members} />} */}
                <h1 className=' text-3xl mt-3 w-full text-center'>{criteria?.samaj}</h1>
                {(members.length != 0) &&  <div className='' > <ProfileList profiles={members} /></div>}
                {/* {members.length != 0 && members.map((profile, index) => {
                    return <div className='' key={index}> <ProfileList profiles={profile} /></div>
                     <MatrimonyVeiw bioData={profile} showDownloadBtn={isDownloadBtnOpen} />
                })} */}

            </div>
            <div className='no-print fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-lg shadow-lg'>
                <button onClick={() => { handlePrint(null, () => printReport.current); }} className=' text-white font-bold px-3 py-1 rounded-xl bg-blue-950'>Print Profiles</button>
            </div>

            {/* <button
  className="no-print fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-lg shadow-lg"
  onClick={() => window.print()}
>
  Print Profiles
</button> */}


            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </>
    )
}

const MyTable = ({ data }) => {
    return (
        <table className='w-full text-sm'>
            <thead>
                <tr className='bg-blue-950 text-white'>
                    <th className="py-1 px-2 border-2">Code</th>
                    <th className="py-1 px-2 border-2">नाम</th>
                    <th className="py-1 px-2 border-2">जन्म दिनांक</th>
                    <th className="py-1 px-2 border-2">स्थान</th>
                    <th className="py-1 px-2 border-2">मांगलिक</th>
                    <th className="py-1 px-2 border-2">गोत्र</th>
                    <th className="py-1 px-2 border-2">शिक्षा</th>
                    <th className="py-1 px-2 border-2">लंबाई</th>
                    <th className="py-1 px-2 border-2">रंग</th>
                    <th className="py-1 px-2 border-2">वजन</th>
                    <th className="py-1 px-2 border-2">मासिक आय</th>
                    <th className="py-1 px-2 border-2">मामा गोत्र</th>
                    <th className="py-1 px-2 border-2">कार्ये</th>
                    <th className="py-1 px-2 border-2">कार्य स्थल</th>
                    <th className="py-1 px-2 border-2">पिता का नाम</th>
                    <th className="py-1 px-2 border-2">माता का नाम</th>
                    <th className="py-1 px-2 border-2">आय</th>
                    <th className="py-1 px-2 border-2">कार्ये</th>
                    <th className="py-1 px-2 border-2">वर्त्तमान शहर</th>
                    <th className="py-1 px-2 border-2">दूरभाष</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, key) => {

                    const { Qr, contactDetails,
                        divorceDetails,
                        educationQualification
                        , extraInformation,
                        familyDetail,
                        kundliDetails,
                        mamaDetails,
                        marriedStatus,
                        matrimonyCode,

                        occupationDetails,
                        othersDetails,
                        personalInformation,
                        photos,
                        samajReferenceDetails

                    } = item;

                    // const { personalPhoto1, personalPhoto2, profilePhoto } = photos
                    // const photo = [profilePhoto, personalPhoto1, personalPhoto2,]

                    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender,
                        heightFeet, heightInch
                        ,
                        gotra,
                        samaj,
                        name,
                        surname,
                        weightInKG,
                        skinColor,

                    } = personalInformation || ""

                    const { birthDateTime, birthPlace, mangalik, rashiByDateOfBirth
                        , rashi } = kundliDetails || ""

                    const { mamaGotra,
                        mamaMobileNo,
                        mamaName,
                        mamaOccupation,
                        mamaPhoto } = mamaDetails || ""

                    const {
                        mobileNo,
                        whatsappNo,
                        email,
                        emailId,
                        facebookId,
                        instagramId,
                        linkedInId
                    } = contactDetails || ""

                    const {
                        fatherName,
                        motherName,
                        fatherOccupation
                    } = familyDetail || ""

                    const { occupation,
                        averageSalaryPerMonth,
                        companyName,
                        designation,
                        occupationCity,
                        occupationCountry,
                        occupationState

                    } = occupationDetails || ""

                    // console.log("occupationCity", occupationCity)
                    const { achievementsAwards, expectationFromRelation, hobbies } = othersDetails || ""
                    return <tr key={key} className='odd:bg-white even:bg-gray-50'>
                        <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                        <td className="py-1 px-2 border-2">{name}</td>
                        <td className="py-1 px-2 border-2">{new Date(birthDateTime).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                        })}</td>
                        <td className="py-1 px-2 border-2">{birthPlace}</td>
                        <td className="py-1 px-2 border-2">{mangalik}</td>
                        <td className="py-1 px-2 border-2">{gotra}</td>
                        <td className="py-1 px-2 border-2">{educationQualification}</td>
                        <td className="py-1 px-2 border-2">{heightFeet}</td>
                        <td className="py-1 px-2 border-2">{skinColor}</td>
                        <td className="py-1 px-2 border-2">{weightInKG}</td>
                        <td className="py-1 px-2 border-2">{averageSalaryPerMonth}</td>
                        <td className="py-1 px-2 border-2">{mamaGotra}</td>
                        <td className="py-1 px-2 border-2">{occupation}</td>
                        <td className="py-1 px-2 border-2">{occupation?.cityName}</td>
                        <td className="py-1 px-2 border-2">{fatherName}</td>
                        <td className="py-1 px-2 border-2">{motherName}</td>
                        <td className="py-1 px-2 border-2">--</td>
                        <td className="py-1 px-2 border-2">{fatherOccupation}</td>
                        <td className="py-1 px-2 border-2">{currentCity?.cityName}</td>
                        <td className="py-1 px-2 border-2">{mobileNo}</td>
                    </tr>
                })}
            </tbody>
        </table>
    );
};

const TableFormat = ({ bioData, }) => {
    const { Qr, contactDetails,
        divorceDetails,
        educationQualification
        , extraInformation,
        familyDetail,
        kundliDetails,
        mamaDetails,
        marriedStatus,
        matrimonyCode,

        occupationDetails,
        othersDetails,
        personalInformation,
        photos,
        samajReferenceDetails

    } = bioData;

    // const { personalPhoto1, personalPhoto2, profilePhoto } = photos
    // const photo = [profilePhoto, personalPhoto1, personalPhoto2,]

    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender,
        heightFeet, heightInch
        ,
        gotra,
        samaj,
        name,
        surname,
        weightInKG,
        skinColor,

    } = personalInformation || ""

    const { birthDateTime, birthPlace, mangalik, rashiByDateOfBirth
        , rashi } = kundliDetails || ""

    const { mamaGotra,
        mamaMobileNo,
        mamaName,
        mamaOccupation,
        mamaPhoto } = mamaDetails || ""

    const {
        mobileNo,
        whatsappNo,
        email,
        emailId,
        facebookId,
        instagramId,
        linkedInId
    } = contactDetails || ""

    const {
        fatherName,
        motherName,
        fatherOccupation
    } = familyDetail || ""

    const { occupation,
        averageSalaryPerMonth,
        companyName,
        designation,
        occupationCity,
        occupationCountry,
        occupationState

    } = occupationDetails || ""

    // console.log("occupationCity", occupationCity)
    const { achievementsAwards, expectationFromRelation, hobbies } = othersDetails || ""

    return <>
        <tr className='odd:bg-white text-sm even:bg-gray-50'>
            {/* <td className="py-1 px-2 border-2 ">{key + 1}</td> */}
            <td className="py-1 px-2 border-2 ">{matrimonyCode}</td>
            <td className="py-1 px-2 border-2 ">{name}</td>
            <td className="py-1 px-2 border-2 ">{birthPlace}</td>
            <td className="py-1 px-2 border-2 ">{mangalik}</td>
            <td className="py-1 px-2 border-2 ">{gotra}</td>
            <td className="py-1 px-2 border-2 ">{educationQualification}</td>
            <td className="py-1 px-2 border-2 ">{skinColor}</td>
            <td className="py-1 px-2 border-2 ">{weightInKG}</td>
            <td className="py-1 px-2 border-2 ">{heightFeet}</td>
            <td className="py-1 px-2 border-2 ">{averageSalaryPerMonth}</td>
            <td className="py-1 px-2 border-2 ">{mamaGotra}</td>
            <td className="py-1 px-2 border-2 ">{occupation}</td>
            <td className="py-1 px-2 border-2 ">{occupation?.cityName}</td>
            <td className="py-1 px-2 border-2 ">{fatherName}</td>
            <td className="py-1 px-2 border-2 ">{motherName}</td>
            <td className="py-1 px-2 border-2 ">--</td>
            <td className="py-1 px-2 border-2 ">{fatherOccupation}</td>
            <td className="py-1 px-2 border-2 ">{currentCity?.cityName}</td>
            <td className="py-1 px-2 border-2 ">{mobileNo}</td>
            {/* <td className="py-1 px-2 border-2 ">{}</td>
        <td className="py-1 px-2 border-2 ">{}</td>
        <td className="py-1 px-2 border-2 ">{}</td>
        <td className="py-1 px-2 border-2 ">{}</td>
        <td className="py-1 px-2 border-2 ">{}</td> */}

        </tr>

    </>

}

export const MatrimonyVeiw = ({ bioData, showDownloadBtn }) => {


    const { Qr, contactDetails,
        divorceDetails,
        educationQualification
        , extraInformation,
        familyDetail,
        kundliDetails,
        mamaDetails,
        marriedStatus,
        matrimonyCode,

        occupationDetails,
        othersDetails,
        personalInformation,
        photos,
        samajReferenceDetails

    } = bioData;

    const { personalPhoto1, personalPhoto2, profilePhoto } = photos
    const photo = [profilePhoto, personalPhoto1, personalPhoto2,]

    const { bloodGroup, currentAddress, currentCity, currentCountry, currentMaritalStatus, currentState, gender,
        heightFeet, heightInch
        ,
        gotra,
        samaj,
        name,
        surname,
        weightInKG,
        skinColor,

    } = personalInformation || ""

    const { birthDateTime, birthPlace, mangalik, rashiByDateOfBirth
        , rashi } = kundliDetails || ""

    const { mamaGotra,
        mamaMobileNo,
        mamaName,
        mamaOccupation,
        mamaPhoto } = mamaDetails || ""

    const {
        mobileNo,
        whatsappNo,
        email,
        emailId,
        facebookId,
        instagramId,
        linkedInId
    } = contactDetails || ""

    const {
        fatherName,
        motherName,
        fatherOccupation
    } = familyDetail || ""

    const { occupation,
        averageSalaryPerMonth,
        companyName,
        designation,
        occupationCity,
        occupationCountry,
        occupationState

    } = occupationDetails || ""

    // console.log("occupationCity", occupationCity)
    const { achievementsAwards, expectationFromRelation, hobbies } = othersDetails || ""
    return (
        <>

        <div>

        </div>














        </>
    )

}

const profiles = [
    {
      name: 'दीपक गुप्ता',
      candidateNumber: '02152',
      birthDate: '04.07.1996',
      birthTime: '04:46 ए.एम.',
      birthPlace: 'नरसिंहगढ़',
      manglik: 'नहीं',
      education: 'बीए',
      height: '5\'3\'\'',
      colorWeight: 'मीडियम- 60 किलो',
      gotra: 'भंडारी',
      mamaGotra: '',
      maternalIncome: '',
      occupation: 'बिजनेस',
      workPlace: '',
      fatherName: 'श्री गंगा प्रसाद गुप्ता',
      address: 'राजगढ़ (म.प्र.)',
      contactNumber: '7566150234',
    },
    // Repeat similar objects for more profiles
  ];

  const ProfileCard = ({ profile }) => {
    console.log("profileprofile",profile)
    if(!profile){
        return<h1>profile not present</h1>
    }
    const {
        _id,
        userType,
        showMobileNo,
        membershipID,
        matrimonyCode,
        matrimonyActivationStatus,
        marriedStatus,
        personalInformation: {
            name,
            surname,
            samaj,
            gotra,
            skinColor,
            handicapped,
            weightInKG,
            heightFeet,
            heightInch,
            gender,
            currentMaritalStatus,
            currentAddress,
            currentPincode,
            currentCity: { cityName, stateID: cityStateID, countryID: cityCountryID },
            currentState: { stateID: stateStateID, stateName, stateShortName, countryID: stateCountryID },
            currentCountry: { countryName, countryID: countryCountryID },
            // motherTongue,
            // languagesKnown,
            // bloodGroup
        },
        // divorceDetails: { divorceDate, childrenDetails },
        kundliDetails: { birthDateTime, birthPlace, mangalik },
        educationQualification,
        occupationDetails: { occupation, companyName, designation, occupationCityText, averageSalaryPerMonth },
        contactDetails: { mobileNo, whatsappNo, emailId },
        familyDetail: {
            fatherName,
            fatherOccupation,
            fatherMobileNo,
            fatherMamaGotra,
            motherName,
            motherMamaGotra,
            brother,
            marriedBrother,
            unmarriedBrother,
            sisters,
            marriedSister,
            unmarriedSister
        },
        mamaDetails: { mamaName, mamaGotra },
        // samajReferenceDetails: {
        //     reference1Name,
        //     reference1Occupation,
        //     reference1MobileNo,
        //     reference1Relation
        // },
        othersDetails: { home, wheeler4, wheeler2 },
        // extraInformation: { anyHealthIssues, specialQualities },
        photos: { profilePhoto, personalPhoto1, personalPhoto2 },
        // remarksNotes,
        Qr,
        // interstRecived,
        // intrestedIn,
        // entryDatetime,
        // updateDate,
        // samajToShow,
        // createdAt,
        // updatedAt,
        // matrimonyEntryId,
        // __v,
        book_id,
        activatedDateTime
    } = profile;
  
    return (
      <>
                  

        <div className=" relative profile-card   min-w-[360px]  bg-white border-2 border-blue-500 p-2 shadow-lg mb-2">
        <Watermark text="vaishyaparinay.com" opacity={0.3} fontSize="1.2rem" />
        
          <div className="flex justify-between ">
            <h1 className="text-lg font-bold text-red-600">{name}</h1>
            <div className="flex flex-col items-end">
              <p className="bg-blue-700 w-[7.52rem] text-[11px] p-1 text-white font-semibold">प्रत्याशी क्र. {matrimonyCode}</p>
              <div className="flex flex-col items-center  text-center">
                <img className="w-[7.52rem] h-[7.52rem] mb-2" src={Domain+profilePhoto} alt="Profile" />
                {/* <div className=' ms-[50%] mb-[-20px] '>
                <img className="w-[3.4rem] h-14" src={Domain+Qr} alt="QR Code" />
                <p className="mt-[-5px] text-[8px]">{matrimonyCode}</p>
                </div> */}
              </div>
            </div>
          </div>
  
          <div className="grid grid-cols-2 font-bold gap-2 text-[10px] mt-[-120px] ">
            <div>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>जन्म दि</span> <span className=' me-2'>:</span> </strong>  {DateFormater.sortMonthName(birthDateTime)}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>मामा गौत्र</span><span className=' me-2'>:</span></strong> {mamaGotra}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>मांगलिक</span><span className=' me-2'>:</span></strong> {mangalik}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>गौत्र</span><span className=' me-2'>:</span></strong> {gotra}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>मा.आय</span><span className=' me-2'>:</span></strong> {averageSalaryPerMonth}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>कार्यक्षेत्र</span><span className=' me-2'>:</span></strong> {cityName}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>शिक्षा</span><span className=' me-2'>:</span></strong> {educationQualification}</p>
              <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>कार्य</span><span className=' me-2'>:</span></strong> {occupation}</p>
            </div>
            <div className=' p:strong-text-red ms-[-40px]  '>
            <p className=' flex'><strong className="text-red-500 w-[2.5rem] flex justify-between"><span>समय</span><span className=' me-2'>:</span></strong> {DateFormater.getTime(birthDateTime)}</p>
              <p className=' flex'><strong className="text-red-500 w-[2.5rem] flex justify-between"><span>ऊँचाई</span><span className=' me-2'>:</span></strong>{heightFeet}.{heightInch} इंच</p>
              <p className=' flex'><strong className="text-red-500 w-[2.5rem] flex justify-between"><span>रंग</span><span className=' me-2'>:</span></strong> {skinColor}</p>
              <p className=' flex'><strong className="text-red-500 w-[2.5rem] flex justify-between"><span>वजन</span><span className=' me-2'>:</span></strong> {weightInKG} KG</p>
              <p className=' flex'><strong className="text-red-500 w-[2.5rem] flex justify-between"><span>स्थान</span><span className=' me-2'>:</span></strong> {birthPlace}</p>
            </div>
          </div>
          <div className="text-[10px] font-bold ">
          <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>पिता</span><span className=' me-2'>:</span></strong> {fatherName}</p>
            <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>पता</span><span className=' me-2'>:</span></strong> {currentAddress}</p>
            <p className=' flex'><strong className="text-red-500 w-[3.4rem] flex justify-between"><span>दूरभाष</span><span className=' me-2'>:</span></strong> {mobileNo}</p>
          </div>
        </div>
      </>
    );
  };
  

  const ProfileList = ({ profiles }) => {
    // Split profiles into groups of 8 for pagination
    const groupedProfiles = profiles.reduce((acc, profile, index) => {
      const groupIndex = Math.floor(index / 8);
      if (!acc[groupIndex]) acc[groupIndex] = [];
      acc[groupIndex].push(profile);
      return acc;
    }, []);
  
    return (
      <div className="md:p-4">
        {groupedProfiles.map((profileGroup, groupIndex) => (
          <div key={groupIndex} className="page-section ">
            {/* Page Header at the top of each group of 8 profiles */}
            {/* <PageHeader /> */}
            <div
              id="profiles-container"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2"
            >
              {profileGroup.map((profile, index) => (
                <ProfileCard key={profile._id || index} profile={profile} />
              ))}
            </div>
  
            {/* Page Break after every group of 8 profiles */}
            {groupIndex < groupedProfiles.length - 1 && <div className="page-break"></div>}
          </div>
        ))}
      </div>
    );
  };
  
  const PageHeader = () => {
    return (
      <div className="w-full h-20 bg-green-500 font-bold text-red-600 text-center">
        This is for testing
      </div>
    );
  };
  

// Sample usage of the ProfileList component with dummy data






// const book = () => {
//     return <>
//         <div className=' h-[550px] bg-white  md:w-[350px] xl:w-[400px] border-2 border-red-600 p-4'>
//             <h1 className=' w-full text-center font-bold text-red-700'>{matrimonyCode}</h1>
//             <h1 className=' font-bold text-center text-red-700'>{name}</h1>
//             <div className={`rounded-b-2xl   relative w-full    flex flex-col `}>
//                 <div className='w-full flex justify-center mt-[-1rem]   xl:mt-[0rem] xl:pb-3'>
//                     <div className=" flex-col text-[14px] leading-[16px] xl:leading-[20px] xl:text-[17px] justify-center w-[100%] py-6  md:py-6 xl:py-0   ">
//                         <div className='  ms-5 text-[14px] font-bold   xl:ms-7  xl:pt-3'>
//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">जन्म दिनांक</p>
//                                 <p className="w-4/5 report-card-colone">

//                                     {new Date(new Date(birthDateTime).getTime() - 19800000).toLocaleString("en-US", {
//                                         year: "numeric",
//                                         month: "short",
//                                         day: "numeric",
//                                         hour: "2-digit",
//                                         minute: "2-digit",
//                                     })}
                                   
//                                 </p>                                </div>
//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">स्थान</p>
//                                 <p className="w-4/5 report-card-colone">{birthPlace} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">विवाह स्थिति</p>
//                                 <p className="w-4/5 report-card-colone">{currentMaritalStatus} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">मांगलिक</p>
//                                 <p className="w-4/5 report-card-colone">{mangalik} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">समाज</p>
//                                 <p className="w-4/5 report-card-colone">{samaj} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">गोत्र</p>
//                                 <p className="w-4/5 report-card-colone">{gotra} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">शिक्षा</p>
//                                 <p className="w-4/5 report-card-colone">{educationQualification} </p>
//                             </div>
//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">लंबाई</p>
//                                 <p className="w-4/5 report-card-colone"> {heightFeet + '.' + heightInch} feet</p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">रंग</p>
//                                 <p className="w-4/5 report-card-colone"> {skinColor}</p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">वजन</p>
//                                 <p className="w-4/5 report-card-colone"> {weightInKG}</p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">मासिक आय</p>
//                                 <p className="w-4/5 report-card-colone"> {averageSalaryPerMonth}</p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">मामा गोत्र</p>
//                                 <p className="w-4/5 report-card-colone"> {mamaGotra}</p>
//                             </div>


//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">कार्ये</p>
//                                 <p className="w-4/5 report-card-colone">{occupation} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">कार्य स्थल</p>
//                                 <p className="w-4/5 report-card-colone">{occupationCity?.cityName} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">पिता का नाम</p>
//                                 <p className="w-4/5  report-card-colone"> {fatherName}</p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">माता का नाम</p>
//                                 <p className="w-4/5  report-card-colone"> {motherName}</p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">पिता आय</p>
//                                 <p className="w-4/5  report-card-colone"></p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">पिता कार्ये</p>
//                                 <p className="w-4/5 report-card-colone">{fatherOccupation} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className=" w-24 xl:w-40">पता</p>
//                                 <p className="w-4/5 report-card-colone">{currentAddress} </p>
//                             </div>

//                             <div className="flex flex-row justify-between ">
//                                 <p className="w-24 xl:w-40">वर्त्तमान शहर</p>
//                                 <p className=" w-4/5 report-card-colone"> {currentCity?.cityName}{" (" + currentState?.stateShortName + ") "}</p>
//                             </div>

//                             <div className="flex flex-row justify-between">
//                                 <p className="w-24 xl:w-40">दूरभाष</p>
//                                 <p className=" w-4/5 report-card-colone"> {mobileNo}</p>
//                             </div>

//                         </div>
//                         <div className={`${showDownloadBtn ? 'w-full flex justify-center' : 'hidden'} `}> <button
//                             onClick={() => downloadImage(`${Domain + profilePhoto}`, `${matrimonyCode}.png`)}
//                             className='text-white font-bold  px-3 py-1 rounded-xl bg-blue-950'
//                         >
//                             Download Image
//                         </button></div>
//                     </div>
//                 </div>
//             </div>

//         </div>
//     </>
// }


