


export class DateFormater {
    // This method can be static as it doesn't rely on instance-specific data
    static sortMonthName(dateString) {
        // Ensure that the input is a valid date
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        // Adjust timezone offset (-19800000ms is -5.5 hours for IST)
        const adjustedDate = new Date(date.getTime() - 19800000);

        // Return formatted date string
        return adjustedDate.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            // hour: "2-digit",
            // minute: "2-digit",
        });
    }

    static getTime(dateString) {
        // Ensure that the input is a valid date
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        // Adjust timezone offset (-19800000ms is -5.5 hours for IST)
        const adjustedDate = new Date(date.getTime() - 19800000);

        // Return formatted date string
        return adjustedDate.toLocaleString("en-US", {
            // year: "numeric",
            // month: "short",
            // day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    }
}


