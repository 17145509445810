import React, { useEffect, useRef } from 'react'
import { deleteIntrestRequest, getIntrestedProfileBothSide } from '../../../service/Services';
import { useState } from 'react';
import { IntrestCard, } from '../../../cards';
import { showErrorAlert, showSuccessAlert } from '../../../Components/ShowAlertCustome';
import LoadingModal from '../../../Forms/LoadingModel';
import { Domain } from '../../../ApiConfig';
import { useSelector } from 'react-redux';
import { gotra } from '../../../constants/staticData';
import { useReactToPrint } from 'react-to-print';

// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import Profile from './Profile';
// import Reports from './Reports';
// import LoadingModal from './LoadingModal';
// import { getIntrestedProfileBothSide, deleteIntrestRequest } from '../api'; // Replace with correct import paths
// import { showErrorAlert, showSuccessAlert } from '../utils/alerts'; // Replace with correct alert utils

export default function IntrestPage() {
    const [page, setPage] = useState('Sended');
    const [sendProfile, setSendProfile] = useState([]);
    const [recivedProfile, setRecivedProfile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteIntrestById, setDeleteIntrestById] = useState(null);
    const user = useSelector(state => state?.auth?.user);

    // Fetch interest profiles
    const fetchInterestProfiles = async () => {
        try {
            setLoading(true);
            const { data } = await getIntrestedProfileBothSide();
            const { sendedByYou, recivedByYou } = data;
            setSendProfile(sendedByYou);
            setRecivedProfile(recivedByYou);
        } catch (error) {
            console.error('Error fetching profiles:', error);
            showErrorAlert('Error fetching profiles. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch profiles on mount
    useEffect(() => {
        fetchInterestProfiles();
    }, []);

    // Handle delete interest request
    useEffect(() => {
        const deleteRequest = async () => {
            if (!deleteIntrestById) return;

            try {
                setLoading(true);
                const response = await deleteIntrestRequest(deleteIntrestById);
                if (response.success) {
                    fetchInterestProfiles();
                    showSuccessAlert('Request deleted successfully.');
                }
            } catch (error) {
                console.error('Error deleting request:', error);
                showErrorAlert('Error deleting request. Please try again later.');
            } finally {
                setLoading(false);
                setDeleteIntrestById(null); // Reset delete state
            }
        };

        deleteRequest();
    }, [deleteIntrestById]);

    // Page rendering logic
    const RenderPage = () => {
        switch (page) {
            case 'Sended':
                return (
                    <>
                        <Profile profiles={sendProfile} text={'sendedByYou'} setDeleteIntrestById={setDeleteIntrestById} />
                        {sendProfile.length === 0 && <h1>No Data</h1>}
                    </>
                );
            case 'Recived':
                return (
                    <>
                        {recivedProfile.length !== 0
                            ? <Profile profiles={recivedProfile} text={'recivedByYou'} setDeleteIntrestById={setDeleteIntrestById} />
                            : <h1>No Data</h1>}
                    </>
                );
            case 'Reports':
                return <Reports sended={sendProfile} recived={recivedProfile} user={user} />;
            default:
                return null;
        }
    };

    // if (loading) {
    //     return <LoadingModal show={loading} text='Loading' />;
    // }

    return (
        <>

            {loading && <LoadingModal show={loading} text='Removing' />}

            <div className="my-4 w-full gap-3 flex justify-around flex-wrap">
                {['Sended', 'Recived', 'Reports'].map((item) => (
                    <button
                        key={item}
                        onClick={() => setPage(item)}
                        className={`cursor-pointer shadow-2xl rounded-lg px-10 py-1 w-72 font-bold text-center ${page === item ? 'text-white bg-blue-600' : 'text-black bg-red-100 border-red-100'
                            }`}
                    >
                        {item === 'Sended' ? 'आपके पसंदीदा रिश्ते' :
                            item === 'Recived' ? 'आपको पसंद करने वाले रिश्ते' : 'रिपोर्टस'}
                    </button>
                ))}
            </div>
            <div className="mb-5">
                <RenderPage />
            </div>
        </>
    );
}


const Profile = ({ profiles, text, setDeleteIntrestById }) => {
    return <div className='flex w-full flex-wrap gap-2 justify-center'>
        {profiles.map((member, index) => {
            const { _id } = member
            console.log("member", member)
            {/* <h1>{member?.sendedBy}</h1> */ }
            return <div key={index}><IntrestCard candidat={member[`${text}`][0]} intrestIds={_id} setDeleteIntrestById={setDeleteIntrestById} /></div>
        })}
    </div>
}



// import { useRef } from "react";
// import { useReactToPrint } from "react-to-print";

const Reports = ({ sended, recived, user }) => {
    const getReport = useRef(null);

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        content: () => getReport.current,
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    // Destructuring user object based on userType
    const {
        photos: { profilePhoto } = {},
        personalInformation: { name, city: { cityName: city } = {}, gotra } = {},
        matrimonyCode,
        membershipCode,
    } = user?.userType === 4
            ? user
            : {
                photos: { profilePhoto: user?.profilePhoto },
                personalInformation: { name: user?.name, city: { name: user?.city?.name }, gotra: user?.gotra },
                matrimonyCode: user?.membershipCode,
            };

    return (
        <>
            <div className="overflow-x-auto" ref={getReport}>
                {sended.length > 0 && (
                    <div>
                        <div className="text-center min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                            <div className="flex px-5 items-center py-2">
                                <img className="w-16 h-16 rounded-2xl" src={Domain + profilePhoto} loading="lazy" alt="Profile" />
                                <div className="ml-5 text-[12px]">
                                    <p className="flex font-bold">{name}</p>
                                    <p className="flex font-bold">{city}</p>
                                    <p className="flex font-bold">{gotra}</p>
                                    <p className="flex font-bold">{matrimonyCode}</p>
                                </div>
                            </div>
                        </div>
                        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                            <thead>
                                <tr className="w-full text-sm bg-blue-950 text-white text-left">
                                    <th className="py-1 px-2 text-left border-2">क्रमांक</th>
                                    <th className="py-1 px-2 text-left border-2">आपके पसंदीदा रिश्ते</th>
                                    <th className="py-1 px-2 text-left border-2">विवाह कोड</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sended.map((candidate, index) => {
                                    const {
                                        personalInformation: { gotra, name } = {},
                                        matrimonyCode,
                                        photos: { profilePhoto } = {},
                                    } = candidate?.sendedByYou?.[0] || {};

                                    return (
                                        <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                            <td className="py-1 px-2 border-2">{index + 1}</td>
                                            <td className="py-1 px-2 border-2 flex min-w-56">
                                                <img className="w-10 h-10 rounded-full mr-8" src={Domain + (profilePhoto || "default.png")} alt={`${name}'s profile`} />
                                                <div className="mt-auto">
                                                    <h2 className="text-black font-bold">{name}</h2>
                                                    <small>{gotra}</small>
                                                </div>
                                            </td>
                                            <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}

                {recived.length > 0 && (
                    <div>
                        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                            <thead>
                                <tr className="w-full text-sm bg-blue-950 text-white text-left">
                                    <th className="py-1 px-2 text-left border-2">क्रमांक</th>
                                    <th className="py-1 px-2 text-left border-2">आपको पसंद करने वाले रिश्ते</th>
                                    <th className="py-1 px-2 text-left border-2">विवाह कोड</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recived.map((candidate, index) => {
                                    const {
                                        personalInformation: { gotra = "Unknown Gotra", name = "Unknown Name" } = {},
                                        matrimonyCode = "N/A",
                                        photos: { profilePhoto = "default.png" } = {},
                                    } = candidate?.recivedByYou?.[0] || {};

                                    return (
                                        <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                            <td className="py-1 px-2 border-2">{index + 1}</td>
                                            <td className="py-1 px-2 border-2 flex min-w-56">
                                                <img className="w-10 h-10 rounded-full mr-8" src={Domain + profilePhoto} alt={`${name}'s profile`} />
                                                <div className="mt-auto">
                                                    <h2 className="text-black font-bold">{name}</h2>
                                                    <small>{gotra}</small>
                                                </div>
                                            </td>
                                            <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {(sended.length > 0 || recived.length > 0) ? (
                <div className="w-full flex justify-center my-5">
                    <button onClick={handlePrint} className="text-white font-bold px-3 py-1 rounded-xl bg-blue-950">Download</button>
                </div>
            ) : (
                <p>No Interest</p>
            )}
        </>
    );
};

// export default Reports;

