import React from 'react'
import { RegistrationForm } from '../../../Forms'

export default function memberProfileEdit() {
    return (
        <div>
            <RegistrationForm />
        </div>
    )
}
