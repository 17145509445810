import React from 'react';
import { calculateAge } from '../Components/reshtey';
import { Domain } from '../ApiConfig';

export default function IntrestCard({ candidat, setDeleteIntrestById, intrestIds }) {
    if (!candidat) {
        return;
    }
    console.log("candidat", candidat);

    const {
        personalInformation: {
            currentCity,
            currentMaritalStatus,
            gotra,
            name,
            heightFeet,
            heightInch,
            gender,
        },
        matrimonyCode,
        occupationDetails: { occupation },
        kundliDetails: { birthDateTime },
        photos: { profilePhoto },
        membershipID,
    } = candidat;

    return (
        <div className='w-[95vw] md:w-[350px] xl:w-[400px] m-2 shadow-2xl shadow-all-sides rounded-3xl p-3 h-[100%] bg-white flex flex-col justify-start'>
            <div className='flex justify-center'>
                <p className='bg-blue-900 w-100 px-4 xl:px-5 rounded-xl py-[0.2rem] xl:py-1 text-[1rem] xl:text-[1.2rem] text-[#FFF411] font-semibold'>
                    Profile ID: {matrimonyCode}
                </p>
            </div>
            <div
                className={`flex mt-2 rounded-t-xl justify-center text-xl xl:text-[16px] font-bold items-center text-white h-[2rem] xl:h-[2.5rem] w-100 ${gender === 'Male' ? "metrimony-card-bg-male-head" : "bg-[#EC268F]"} `}
            >
                {name}
            </div>

            <div
                className={`rounded-b-2xl relative w-full ${gender === 'Male' ? "metrimony-card-bg-male-body" : "metrimony-card-bg"} flex flex-col border-dashed border-gray-500 border-b-2 border-e-2 border-s-2`}
            >
                <div className='w-full flex justify-center mt-[-1rem] h-[100%] xl:mt-[0rem] xl:pb-3'>
                    <div className='w-[30%] h-full flex items-center'>
                        <img className='object-cover' src={`${Domain}${profilePhoto}`} loading='lazy' />
                    </div>

                    <div className="text-[#FFF411] flex-col text-[12px] leading-[16px] xl:leading-[20px] xl:text-[16px] justify-center w-[100%] py-6 md:py-6 xl:py-0">
                        <div className='h-[100%] ms-5 text-[10px] font-bold xl:ms-7 xl:pt-3'>
                            <div className="flex flex-row justify-between">
                                <p className="w-20 xl:w-40">गोत्र</p>
                                <p className="w-4/5 metrimony-card-colone">{gotra}</p>
                            </div>
                            <div className="flex flex-row justify-between">
                                <p className="w-20 xl:w-40">उम्र</p>
                                <p className="w-4/5 metrimony-card-colone">{calculateAge(birthDateTime)} वर्ष</p>
                            </div>
                            <div className="flex flex-row justify-between">
                                <p className="w-20 xl:w-40">लंबाई</p>
                                <p className="w-4/5 metrimony-card-colone">{heightFeet}.{heightInch} feet</p>
                            </div>
                            <div className="flex flex-row justify-between">
                                <p className="w-20 xl:w-40">वर्त्तमान शहर</p>
                                <p className="w-4/5 metrimony-card-colone">{currentCity?.cityName}</p>
                            </div>
                            <div className="flex flex-row justify-between">
                                <p className="w-20 xl:w-40">व्यवसाय</p>
                                <p className="w-4/5 metrimony-card-colone">{occupation}</p>
                            </div>
                            <div className="flex flex-row justify-between">
                                <p className="w-20 xl:w-40">वैवाहिक स्थिति</p>
                                <p className="w-4/5 metrimony-card-colone">{currentMaritalStatus}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-end mt-1'>
                {setDeleteIntrestById && (
                    <button
                        onClick={() => setDeleteIntrestById(intrestIds)}
                        className='bg-blue-900 text-sm cursor-pointer font-bold text-white px-5 py-1 rounded-lg'
                    >
                        यह रिश्ता हटाएँ
                    </button>
                )}
            </div>
        </div>
    );
}
