import React, { useState } from 'react'
import { CommitteeCard, MembersCard } from '../../../Components'
import UserData from "../../../fakeData/UserData.js";
import Card from '../../../Components/Card.js';
import Form from "../../../Forms.js/Criterial.js"
// import CommitteeMembers from "../../../fakeData/commeteeFake.js"
import umeshGupta from "../../../assets/CommitteeMember/umaeshGupta.png"
import girishAgrawal from "../../../assets/CommitteeMember/girish agrawal card.png"
import vinodAgrawal from "../../../assets/CommitteeMember/vinod agrawalcard.png"
import dineshMittal from "../../../assets/CommitteeMember/dineshcard.png"
import arrvindBagdi from "../../../assets/CommitteeMember/arvind bagdi.png"
import dhirajKandelwal from "../../../assets/CommitteeMember/dheerajcard.png"
import kailash from "../../../assets/CommitteeMember/kailashcard.png"
import chelawatcard from "../../../assets/CommitteeMember/chelawatcard.png"
import rajeshGarg from "../../../assets/CommitteeMember/rajeshgargcard.png"
import shev from "../../../assets/CommitteeMember/shivcard.png"



const CommitteeMember = [umeshGupta, girishAgrawal, vinodAgrawal, dineshMittal, arrvindBagdi, dhirajKandelwal, rajeshGarg, shev]
export default function Committee() {
    // const [CommitteeMember, setCommitteeMembers] = useState(CommitteeMembers);

    return (
        <>
            <div className='    flex flex-wrap gap-3 justify-center '>

                {/* <div className='flex flex-wrap justify-around mt-5 p-3   w-[100vw] bg-[#151B54]'> */}
                {/* <Form data={data} label={"सदस्य का नाम"} />
                <Form data={data} label={"समाज मैं पद"} />
                <Form data={data} label={"मोबाइल नंबर"} /> */}
                {/* <Form data={data} label={"lable"} /> */}
                {/* </div> */}

                <div className='    flex flex-wrap  gap-0 sm:gap-3  justify-center '>
                    {/* <CommitteeCard /> */}
                    {CommitteeMember.map((img, index) =>
                    (

                        <img className='w-full   md:w-[350px] xl:w-[450px] ' src={img} loading='lazy' />

                    )
                    )}
                </div>
                {/* <CommitteeCard Committeemember={member} key={index} /> */}

                {/* {members.map((member, index) =>
            (
                <MembersCard user={member} key={index} />
            )
            )} */}
            </div>

        </>
    )
}









