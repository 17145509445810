import React from 'react'
import { GuestRegistrationForm } from "../../Forms/GuestRegistrationForm"

export default function GuestRegistration() {
    return (
        <div>
            <GuestRegistrationForm />
        </div>
    )
}
