// import React from 'react'
import { VivahGallery } from '../../../cards'
import { ShowIntrest, searchMatrimonyForgalleryPage } from '../../../service/Services';
import React, { useEffect, useState } from 'react'
import UserData from "../../../fakeData/UserData.js";
import { Reshtey } from '../../../Components/index.js';
import male from '../../../assets/matrimonyCard/malematricardview.png'
import female from '../../../assets/matrimonyCard/femalematricardview.png'
import { Link } from 'react-router-dom';
import FormButton from '../../../Forms/AdminButtons.js';
// import { candidatCard, Pagination } from '../../../Components/index.js';
import Card from '../../../Components/Card.js';
import { get_member, searchMemberForMemberPage } from '../../../ApiConfig.js';
import axios from 'axios';
import { SearchCriteria, SearchDropDownCriteria } from '../../../FormsInputes.js/index.js';
import useIntersectionObserver from '../../../Helper/useIntersectionObserver.js';
import { storePagination } from '../../../service/sessionStor.js';
import { getMatrimony, getMember } from '../../../service/Services.js';
import { useDispatch, useSelector } from 'react-redux';
import { setPagination } from '../../../Redux/paginationSlicer.js';
import { currentMarritalStatus, gotra, salaries, vaishaSamaj, vaishaSamajAndGotra } from "../../../constants/staticData.js"
import ImageCarousel from '../../../Components/carousel.js';

import { occupations, ageRange } from "../../../constants/staticData.js"
import { showErrorAlert, showSuccessAlert } from '../../../Components/ShowAlertCustome.js';
import LoadingModal from '../../../Forms/LoadingModel.js';

export default function VivahPage() {


    const [currentPage, setCurrentPage] = useState(1);
    const [criteria, setCriteria] = useState({ averageSalaryPerMonth: "", currentMaritalStatus: "", companyName: "", cityName: "", currentCity: "", name: "", samaj: "", gotra: "", cityName: '', cityId: '', occupation: "", gender: "", minAge: "", maxAge: "" })
    const [byName, setByName] = useState([])
    const [city, setCity] = useState([])

    const [gotra, setGotra] = useState(vaishaSamajAndGotra[criteria?.samaj])
    // const [occupation, setOccupation] = useState([])
    const [surename, setSurname] = useState([])
    // const [gotra, setGotra] = useState(gotra)
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [intrestLoading, setIntrestLoading] = useState(false);
    const [candidat, setCandidat] = useState([]);
    const [reciverId, setReciverId] = useState(null)
    // const [paginationData, setPagination] = useState({})
    // const [totalPages, setTotalPages] = useState(0);

    const paginationData = useSelector(state => state?.pagination?.pagination)
    const lastItemRef = useIntersectionObserver({ currentPage, paginationData, setCurrentPage });
    const totalItemInPage = 20;
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                const { averageSalaryPerMonth, companyName, cityName, currentCity, name, samaj, gotra, gender, minAge, maxAge, currentMaritalStatus, occupation, ...cityData } = criteria;

                // console.log('criteria', criteria)
                const memberData = await searchMatrimonyForgalleryPage({ averageSalaryPerMonth, companyName, cityName, currentCity, name, samaj, gotra, gender, minAge, maxAge, currentMaritalStatus, occupation, city: cityData?.city?.id }, requestOptions)
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
                if (memberData?.success) {
                    const data = memberData?.data || [];
                    // setTotalPages(memberData?.pagination?.totalPages);
                    setPagination(memberData?.pagination)
                    dispatch(setPagination(memberData?.pagination))
                    // storePagination(memberData?.pagination)
                    // console.log(memberData?.pagination)
                    setCandidat(pre => [...pre, ...data])
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [currentPage, criteria])

    useEffect(() => {
        setCandidat([])
        setPagination({})
        setCurrentPage(pre => 1)
    }, [criteria])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const showIntrest = async () => {

            try {
                setIntrestLoading(true)
                const isIntrestAdd = await ShowIntrest({ revedMembershipID: reciverId })
                if (isIntrestAdd.success) {
                    showSuccessAlert('profile Added')
                }


            } catch (error) {
                console.log("error", error)
                showErrorAlert(error)
            } finally {
                setIntrestLoading(false)
                setReciverId(null)
            }
        }

        if (reciverId !== null) {
            showIntrest()
        }

    }, [reciverId])

    useEffect(() => {
        setGotra(vaishaSamajAndGotra[criteria?.samaj])
    }, [criteria?.samaj])

    return (
        <>
            <LoadingModal show={intrestLoading} text='Adding' />
            <div className='flex h-100    flex-wrap flex-col xl:flex-row gap-2 justify-center'>
                <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">
                    <div className="   ">
                        {/* name */}
                        <SearchCriteria
                            options={byName}
                            search={'name'}
                            id={'name'}
                            setOptions={setCriteria}
                            label={"नाम"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={'  text-black font-bold'}

                        />
                    </div>

                    {/* surename */}
                    {/* <div className="w-100  ">
                        <SearchCriteria
                            options={surename}
                            search={'surname'}
                            id={'surname'}
                            setOptions={setCriteria}
                            label={"उपनाम"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div> */}

                    {/* gotra */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={currentMarritalStatus}
                            search={'currentMaritalStatus'}
                            id={'currentMaritalStatus'}
                            setOptions={setCriteria}
                            label={"वैवाहिक स्थिति"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    <div className="   ">
                        {/* samaj */}
                        <SearchCriteria
                            options={vaishaSamaj}
                            search={'samaj'}
                            id={'samaj'}
                            setOptions={setCriteria}
                            label={"समाज"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={'  text-black font-bold'}
                        />
                    </div>

                    {/* gotra */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={gotra}
                            search={'gotra'}
                            id={'gotra'}
                            setOptions={setCriteria}
                            label={"गोत्र"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* Gender */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={["Male", "Female"]}
                            search={'gender'}
                            id={'gender'}
                            setOptions={setCriteria}
                            label={"Gender"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* minAge && maxAge */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={ageRange}
                            search={'minAge'}
                            id={'minAge'}
                            setOptions={setCriteria}
                            label={"किस उम्र से  "}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    <div className="w-100  ">
                        <SearchCriteria
                            options={ageRange}
                            search={'maxAge'}
                            id={'maxAge'}
                            setOptions={setCriteria}
                            label={"किस उम्र तक"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* city */}
                    {/* <div className="w-100  ">
                        <SearchCriteria
                            options={city}
                            search={'city'}
                            id={'city'}
                            setOptions={setCriteria}
                            label={"शहर"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div> */}

                    {/* occupation */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={occupations}
                            search={'occupation'}
                            id={'occupation'}
                            setOptions={setCriteria}
                            label={"व्यवसाय"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* <div className="w-100  ">
                        <SearchCriteria
                            options={salaries}
                            search={'occupation'}
                            id={'occupation'}
                            setOptions={setCriteria}
                            label={"व्यवसाय"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div> */}

                    <div className="w-100  ">
                        <SearchCriteria
                            options={salaries}
                            search={'averageSalaryPerMonth'}
                            id={'averageSalaryPerMonth'}
                            setOptions={setCriteria}
                            label={"न्यूनतम वेतन"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    <div className="w-100  ">
                        <SearchCriteria
                            options={[]}
                            search={'companyName'}
                            id={'companyName'}
                            setOptions={setCriteria}
                            label={"नौकरी कंपनी का नाम"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    <div className="w-100  ">
                        <SearchCriteria
                            options={[]}
                            search={'cityName'}
                            id={'cityName'}
                            setOptions={setCriteria}
                            label={"नौकरी का शहर"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    <div className="w-100  ">
                        <SearchCriteria
                            options={[]}
                            search={'currentCity'}
                            id={'currentCity'}
                            setOptions={setCriteria}
                            label={"वर्तमान शहर"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* if (averageSalaryPerMonth) query['occupationDetails.averageSalaryPerMonth'] = { $regex: new RegExp(averageSalaryPerMonth, 'i') };
        if (companyName) query['occupationDetails.companyName'] = { $regex: new RegExp(companyName, 'i') };
        if (cityName) query['occupationDetails.occupationCity.cityName'] = { $regex: new RegExp(cityName, 'i') };
        if (currentCity) query['personalInformation.currentCity.cityName'] = { $regex: new RegExp(currentCity, 'i') }; */}

                </div>
                {/* <div className="flex flex-col items-center justify-center   bg-gray-100">
                    <ImageCarousel />
                </div> */}

                <div className="flex w-full flex-wrap gap-2 justify-center">

                    {/* (members.length === index + 1) ?
                            <div key={index} className='thi si last index' ref={lastItemRef} ><Reshtey rishtey={member} key={index} style={{ color: "red" }} /> </div> : <div key={index} > <Reshtey rishtey={member} key={index} style={{ color: "red" }} /> </div>
                    ))} */}
                    {candidat?.map((member, index) => (
                        (candidat.length === index + 1) ? <div key={index} className='thi si last index' ref={lastItemRef} > <VivahGallery candidat={member} setReciverId={setReciverId} /></div> : <div key={index} className=''  > <VivahGallery candidat={member} setReciverId={setReciverId} /></div>
                    ))}
                </div>
                {isLoading && <div className="flex items-center justify-center">
                    <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
                </div>}
                {!paginationData?.hasNextPage && <h1 className=' text-center'>End</h1>}

                {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
            </div>
            {/* </div> */}

        </>
    )
}
