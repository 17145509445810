import React from 'react';

const Watermark = ({ text, opacity = 0.05, fontSize = '2rem', rotate = -45 }) => {
    const watermarkStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(' + rotate + 'deg)',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: opacity,
        fontSize: fontSize,
        color: 'rgba(0, 0, 0, 1)', // Use RGBA for better opacity control
        zIndex: 0, // Ensure this is behind other elements
        pointerEvents: 'none', // Don't let it block interaction
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };

    return (
        <div className=' font-extrabold font-serif' style={watermarkStyle}>
            <span>{text}</span>
        </div>
    );
};

export default Watermark;
